import React, { useState, useEffect, Suspense } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import OrderAction from 'redux/order/actions'
import { createStructuredSelector } from 'reselect'
import {
  selectCloseOrder,
  selectCloseOrderData,
  selectActiveStores,
} from 'redux/storeinfo/closeorderselectors'
import { selectSettings } from 'redux/settings/selectors'
import { Input, Form, Space, Drawer, Table, DatePicker, Spin } from 'antd'
import CloseOrderSummary from './CloseOrderSummary'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    stores: selectActiveStores,
    ordertwo: selectCloseOrder,
    orderDatatwo: selectCloseOrderData,
    settings: selectSettings,
  })

const ClosedOrders = ({ dispatch, ordertwo, orderDatatwo, settings, intl: { formatMessage } }) => {
  const [page, setPage] = useState(1)

  const [valueone] = useState(new Date())

  const datevalue = moment(valueone).format('YYYY-MM-DD')

  const dateFormat = 'YYYY-MM-DD'

  function disabledDate(current) {
    return current > moment().startOf('day')
  }

  const path = window.location.pathname

  const idval = path.replace('/manage/store-info/', '')

  useEffect(() => {
    dispatch({
      type: OrderAction.LOAD_REPORT,
      payload: {
        datevalue,
        idval,
      },
    }) // eslint-disable-next-line
  }, [dispatch, idval])

  function onChange(date, values) {
    if (values === '') {
      dispatch({
        type: OrderAction.LOAD_CLOSED_ORDER_REPORT,
        payload: {
          idval,
          values,
        },
      })
    } else {
      dispatch({
        type: OrderAction.LOAD_CLOSED_ORDER_REPORT,
        payload: {
          idval,
          values,
        },
      })
    }
  }

  const showOrderSummary = orderRecord => {
    dispatch({
      type: OrderAction.SHOW_CLOSE_ORDER_TRANSACTIONS,
      payload: {
        order: orderRecord,
      },
    })
  }

  const hideOrderSummary = () => {
    dispatch({
      type: OrderAction.HIDE_CLOSE_ORDER_DRAWER,
    })
  }

  const onSearch = value => {
    dispatch({
      type: OrderAction.SET_STATE,
      payload: { searchcloseorder: value },
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.closedorder' }),
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.closeorder.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}`,
    },
    {
      title: formatMessage({ id: 'text.closeorder.orderno' }),
      dataIndex: 'order_number',
      key: 'order_number',
    },
    {
      title: formatMessage({ id: 'text.closeorder.ordertype' }),
      dataIndex: 'order_type',
      key: 'order_type',
    },
    {
      title: formatMessage({ id: 'text.closeorder.tableno' }),
      dataIndex: 'table_number',
      key: 'table_number',
    },
    {
      title: formatMessage({ id: 'text.closeorder.seatno' }),
      dataIndex: 'seat_number',
      key: 'seat_number',
    },
    {
      title: formatMessage({ id: 'text.closeorder.ordertotal' }),
      dataIndex: 'order_total',
      key: 'order_total',
      render: text => <>${text.toFixed(2)}</>,
    },
    {
      title: formatMessage({ id: 'text.closeorder.paymentstatus' }),
      key: 'payment_status',
      render: (text, record) => (
        <>
          {text.payment_status}
          &nbsp;&nbsp;
          <i className="fa fa-list-alt" aria-hidden="true" onClick={() => showOrderSummary(record)}>
            <></>
          </i>
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.closeorder.user' }),
      dataIndex: ['staff', 'name'],
      key: 'name',
    },
  ]

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              onSearch={onSearch}
              style={{ width: '100%', height: '100%' }}
              placeholder={formatMessage({ id: 'text.closeorder.search' })}
              size="large"
              allowClear
            />
          </Form.Item>
        </div>
        <div className="col-md-8 text-right">
          <Space>
            <DatePicker
              defaultValue={moment(datevalue)}
              onChange={onChange}
              selected={datevalue}
              format={dateFormat}
              style={{ height: '40px' }}
              allowClear={false}
              disabledDate={disabledDate}
            />
          </Space>
        </div>
      </div>
      <div className="kit__utils__table">
        <Suspense fallback={<Spin />}>
          {orderDatatwo?.length >= 0 ? (
            <Table
              dataSource={orderDatatwo}
              columns={columns}
              locale={locale}
              rowKey="_id"
              pagination={{
                onChange(current) {
                  setPage(current)
                },
              }}
            />
          ) : (
            <Table
              columns={columns}
              locale={locale}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
              }}
            />
          )}
        </Suspense>

        <Drawer
          title={formatMessage({ id: 'text.orderinfo' })}
          placement="right"
          destroyOnClose
          visible={ordertwo.displayCloseOrderSummaryDrawer}
          onClose={hideOrderSummary}
          width={settings.isMobileView ? '100%' : '44%'}
        >
          <CloseOrderSummary />
        </Drawer>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(ClosedOrders))
