import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'text.space': '{count, plural, =1{1} other{#}}',
  'text.default': 'Default',
  'text.makedefault': 'Make this card as default',
  'action.addnewcard': 'Add New Card',
  'action.add': 'Add',
  'action.addcard': 'Add Card',
  'action.save': 'Save',
  'action.yes': 'Yes',
  'action.no': 'No',
  'action.addCustomImage': 'Add Custom Image',
  'text.logo': 'Logo',
  'text.showaddcard': 'Add New Card',
  'text.storedesign': 'Store Design',
  'text.viewStore': 'View Store',
  'form.label.mobileappcoverimage': 'Mobile App Cover Image',
  'form.label.themeColor': 'Theme Color',
  'action.changeColor': 'Change Color',
  'desc.coverImage': 'Cover Image',
  'desc.backgroundImage': 'Cover Image',
  'action.change': 'Change',
  'text.storeino.tab.orders': 'Orders',
  'text.deviceinfo.Settings': 'Settings',
  'text.tabepaysettings': 'Table Pay Settings',
  'text.storeino.tab.tablepaysettings': 'Table Pay Settings',
  'text.tablepaysettings': 'Table Pay Settings',
  'form.label.webstorebackgroundimage': 'Web Store background Image',
  'note.logoSize':
    'Image dimension recommended in JPG or PNG format with the size less than 200kb.',
  'action.resend': 'Resend',
  'action.edit': 'Edit',
  'action.remove': 'Remove',
  'action.retry': 'Retry',
  'action.update': 'Update',
  'action.void': 'Void',
  'text.resendinvoice': 'Resend Invoice',
  'text.showresendinvoice': 'Resend Invoice',
  'text.voidinvoice': 'Void Invoice',
  'text.showvoidinvoice': 'Void Invoice',
  'text.resendinvoice.description': 'Do you want to resend this invoice?',
  'text.voidinvoice.description': 'Do you want to void this invoice?',
  'text.retrypayment': 'Retry Payment',
  'text.showretrypayment': 'Retry Payment',
  'text.showupdatepayment': 'Update Payment',
  'text.retrypayment.description': 'Do you want to retry the payment?',
  'text.updatepayment.description': 'Do you want to update the payment?',

  'title.helmet.store': 'Manage Stores',
  'text.manage.stores': 'Manage Stores',
  'text.store.tab.active': 'Active',
  'text.store.tab.inactive': 'Inactive',
  'text.store.tab.demo': 'Demo',

  'text.active.store.sno': 'S.No',
  'text.active.store.store_name': 'Store Name',
  'text.active.store.phone_number': 'Phone Number',
  'text.active.store.email': 'Email',
  'text.active.store.reseller': 'Reseller',
  'text.active.store.agent': 'Agent',
  'text.active.store.billing_type': 'Payment Method',
  'action.active.store.billing_type': 'Change Payment Method',
  'text.active.store.pos_software': 'Restaurant Software',
  'text.active.store.search': 'Search by store name or email or reseller',

  'text.inactive.store.sno': 'S.No',
  'text.inactive.store.store_name': 'Store Name',
  'text.inactive.store.phone_number': 'Phone Number',
  'text.inactive.store.email': 'Email',
  'text.inactive.store.reseller': 'Reseller',
  'text.inactive.store.agent': 'Agent',
  'text.inactive.store.billing_type': 'Payment Method',
  'action.inactive.store.billing_type': 'Change',
  'text.inactive.store.search': 'Search by store name or email or reseller',

  'text.demo.store.sno': 'S.No',
  'text.demo.store.store_name': 'Store Name',
  'text.demo.store.phone_number': 'Phone Number',
  'text.demo.store.email': 'Email',
  'text.demo.store.reseller': 'Reseller',
  'text.demo.store.agent': 'Agent',
  'text.demo.store.billing_type': 'Payment Method',
  'action.demo.store.billing_type': 'Change',
  'text.demo.store.search': 'Search by store name or email or reseller',

  'title.helmet.storeinfo': 'Store Info',
  'text.storeinfo': 'Store Info',
  'text.storeino.tab.storeinfo': 'Store Info',
  'text.storeino.tab.subscriptioninfo': 'Subscription',
  'text.storeino.tab.deviceinfo': 'Device Info',
  'text.storeino.tab.paymenthistory': 'Payment History',
  'text.storeino.tab.notes': 'Notes',
  'text.storeino.tab.openorder': 'Open Order',
  'text.storeino.tab.closeorder': 'Closed Order',
  'text.storeino.tab.coseorder': 'Closed Order',

  'text.storeino.tab.windowsclient': 'Windows Client',
  'text.storeino.tab.paymentterminal': 'Payment Terminal',

  'title.helmet.deviceinfo': 'Device Info',
  'text.deviceinfo.sno': 'S.No',
  'text.deviceinfo.devicename': 'Device Name',
  'text.deviceinfo.ipaddress': 'IP Address',
  'text.deviceinfo.macaddress': 'MAC Address',
  'text.deviceinfo.lastactivity': 'Last Activity',
  'text.deviceinfo.networkstatus': 'Network Status',
  'text.deviceinfo.logs': 'Logs',
  'text.deviceinfo.devicetype': 'Device Type',
  'text.deviceinfo.viewlogs': 'View Logs',
  'text.devicelogs': 'Device Logs',
  'text.deviceinfo.servicestatus': 'Service Status',
  'text.deviceinfo.servicestatusrunning': 'Running',
  'text.deviceinfo.servicestatusstopped': 'Stopped',
  'text.deviceinfo.version': 'Version',
  'form.paymentterminal.placeholder.search': 'Search by device name',
  'form.windowsclient.placeholder.search': 'Search by device name',
  'text.errorlogsmessage': 'No Error Logs to show',

  // Open Order Table Header

  'text.openorder.sno': 'S.No',
  'text.openorder.orderno': 'Order Number',
  'text.openorder.ordertype': 'Order Type',
  'text.openorder.tableno': 'Table Number',
  'text.openorder.seatno': 'Seat Number',
  'text.openorder.servername': 'Server Name',
  'text.openorder.ordertotal': 'Order Total',
  'text.openorder.paymentstatus': 'Payment Status',
  'text.openorder.order_status': 'Order Status',
  'text.openorder.orderinfo': 'Order Info',
  'text.openorder.orderdetail': 'Order Detail',
  'text.openorder.search': 'Search by order number or user',
  'text.orderamountdue': 'Amount Due',
  'text.orderno': 'Order#',
  'text.orderamountpaidwithtip': 'Amount Paid (Incl.tip)',
  'text.orderamountpaid': 'Amount Paid',
  'text.openorder.user': 'User',

  'text.ordertableno': 'Table Number',
  'text.orderseatno': 'Seat Number',
  'text.orderpaymentstatus': 'Payment Status',
  'text.ordertype': 'Order Type',
  'text.ordertip': 'Tip',
  'text.orderusername': 'User',
  'text.ordercreatedon': 'Created',
  'text.orderinfo': 'Order Summary',
  'text.orderpaymentmethod': 'Payment Method',
  'text.ordertransactiontype': 'Transaction Type',
  'text.orderauthnumber': 'Auth Number',
  'text.ordertransactiondate': 'Transaction Date/Time',

  'text.closeorder.sno': 'S.No',
  'text.closeorder.orderno': 'Order Number',
  'text.closeorder.ordertype': 'Order Type',
  'text.closeorder.tableno': 'Table Number',
  'text.closeorder.seatno': 'Seat Number',
  'text.closeorder.servername': 'Server Name',
  'text.closeorder.ordertotal': 'Order Total',
  'text.closeorder.orderdetail': 'Order Detail',
  'text.closeorder.paymentstatus': 'Payment Status',
  'text.closeorder.search': 'Search by order number or user',
  'text.closeorder.user': 'User',

  'form.storeinfo.label.storename': 'Store Name',

  'text.storedetail.storedetailinfo': 'Store Details',
  'text.storedetail.contactdetails': 'Contact Details',
  'text.storedetail.businessdetails': 'Business Details',
  'action.storedetail.update': 'Update',

  'form.storedetail.label.storename': 'Store Name',
  'form.storedetail.label.storeaddress': 'Address',
  'form.storedetail.label.storecity': 'City',
  'form.storedetail.label.storeprovince': 'Province',
  'form.storedetail.label.storepostalcode': 'Zip/Postal Code',
  'form.storedetail.label.storecountry': 'Country',
  'form.storedetail.label.storebusinessid': 'Business ID',
  'form.storedetail.label.storephone': 'Phone Number',
  'form.storedetail.label.storealternatephone': 'Alt. Phone Number',
  'form.storedetail.label.storeemail': 'Email',
  'form.storedetail.label.storeowner': 'Business Owner',
  'form.storedetail.label.storetype': 'Restaurant Type',
  'form.storedetail.label.storelocations': 'No. of Locations',
  'form.storedetail.label.storeintersection': 'Intersection/Plaza',
  'form.storedetail.label.storecategory': 'Category',
  'form.storedetail.label.storepricerange': 'Price Range',
  'form.storedetail.label.firstName': 'First Name',
  'form.storedetail.label.lastName': 'Last Name',
  'form.storedetail.label.email': 'Email',
  'form.storedetail.label.phone_number': 'Phone Number',
  'form.storedetail.label.alternate_phone_number': 'Alt. Phone Number',
  'form.storedetail.label.businessid': 'Business ID',
  'form.storedetail.label.locationid': 'Location ID',
  'form.storedetail.label.nooflocations': 'No of Locations',
  'form.storedetail.label.category': 'Category',
  'form.storedetail.label.intersectionplaza': 'Intersection Plaza',
  'form.storedetail.label.pricerange': 'Price Range',
  'form.label.businesstype': 'Business Type',

  'form.storedetail.placeholder.storename': 'Enter the store name',
  'form.storedetail.placeholder.storetype': 'Enter the Restaurant Type',
  'form.storedetail.placeholder.storeaddress': 'Enter the address',
  'form.storedetail.placeholder.storecity': 'Enter the City',
  'form.storedetail.placeholder.storeprovince': 'Enter the Province',
  'form.storedetail.placeholder.storepostalcode': 'Enter the Zip/Postal code',
  'form.storedetail.placeholder.storecountry': 'Enter the country',
  'form.storedetail.placeholder.firstName': 'Enter the first name',
  'form.storedetail.placeholder.lastName': 'Enter the last name',
  'form.storedetail.placeholder.email': 'Enter the email',
  'form.storedetail.placeholder.phone_number': '(123) 456-7890',
  'form.storedetail.placeholder.alternate_phone_number': '(123) 123-1234',
  'form.storedetail.placeholder.businessid': 'Enter the business ID',
  'form.storedetail.placeholder.locationid': 'Enter the location ID',
  'form.storedetail.placeholder.nooflocations': 'Locations',
  'form.storedetail.placeholder.category': 'Enter the category',
  'form.storedetail.placeholder.intersectionplaza': 'Enter the Intersection Plaza',

  'title.helmet.user': 'Manage Users',
  'text.user': 'Manage Users',
  'text.userinfo.sno': 'S.No',
  'text.userinfo.name': 'Name',
  'text.userinfo.email': 'Email',
  'text.userinfo.phone_number': 'Phone Number',
  'action.userinfo.edit': 'Edit',
  'text.userinfo.status': 'Status',
  'text.userinfo.action': 'Action',
  'text.userinfo.active': 'Active',
  'text.userinfo.inactive': 'Inactive',
  'text.user.placeholder.search': 'Search..',

  'title.helmet.invoice': 'Invoice',
  'text.viewinvoices': 'View Invoice',
  'text.invoices': 'Manage Invoices',
  'text.paidinvoicelabel': 'PAID',
  'text.notpaidinvoicelabel': 'NOT PAID',
  'text.invoices.placeholder.search': 'Search..',

  'text.invoice.creditcardbilling': 'Credit Card Billing',
  'text.invoice.creditcard.paid': 'Paid Invoices',
  'text.invoice.creditcard.unpaid': 'Unpaid Invoices',
  'text.paymenthistory.paid.sno': 'S.No',
  'text.paymenthistory.paid.invoice_no': 'Invoice Number',
  'text.paymenthistory.paid.storename': 'Store Name',
  'text.paymenthistory.paid.invoice_amount': 'Invoice Amount',
  'text.paymenthistory.paid.invoice_date': 'Invoice Date',
  'text.paymenthistory.paid.payment_status': 'Payment Status',
  'text.paymenthistory.paid.payment_method': 'Payment Method',
  'text.paymenthistory.paid.payment_date': 'Payment Date',
  'text.paymenthistory.paid..placeholder.search': 'Search..',

  'text.creditcard.paid.sno': 'S.No',
  'text.creditcard.paid.invoice_no': 'Invoice Number',
  'text.creditcard.paid.storename': 'Store Name',
  'text.creditcard.paid.invoice_amount': 'Invoice Amount',
  'text.creditcard.paid.invoice_date': 'Invoice Date',
  'text.creditcard.paid.payment_status': 'Payment Status',
  'text.creditcard.paid.payment_method': 'Payment Method',
  'text.creditcard.paid.payment_date': 'Payment Date',
  'text.creditcard.paid..placeholder.search': 'Search by invoice number or invoice date',

  'text.invoice.bamboradebit.paid': 'Paid Invoices',
  'text.invoice.bamboradebit.unpaid': 'Unpaid Invoices',
  'text.bamboradebit.paid.sno': 'S.No',
  'text.bamboradebit.paid.invoice_no': 'Invoice Number',
  'text.bamboradebit.paid.storename': 'Store Name',
  'text.bamboradebit.paid.invoice_amount': 'Invoice Amount',
  'text.bamboradebit.paid.invoice_date': 'Invoice Date',
  'text.bamboradebit.paid.payment_status': 'Payment Status',
  'text.bamboradebit.paid.payment_method': 'Payment Method',
  'text.bamboradebit.paid.payment_date': 'Payment Date',
  'text.bamboradebit.paid..placeholder.search': 'Search..',

  'text.invoice.cloverbilling': 'Clover Billing',
  'text.invoice.cloverecommerce.paid': 'Paid Invoices',
  'text.invoice.cloverecommerce.unpaid': 'Unpaid Invoices',
  'text.cloverecommerce.paid.sno': 'S.No',
  'text.cloverecommerce.paid.invoice_no': 'Invoice Number',
  'text.cloverecommerce.paid.storename': 'Store Name',
  'text.cloverecommerce.paid.invoice_amount': 'Invoice Amount',
  'text.cloverecommerce.paid.invoice_date': 'Invoice Date',
  'text.cloverecommerce.paid.payment_status': 'Payment Status',
  'text.cloverecommerce.paid.payment_method': 'Payment Method',
  'text.cloverecommerce.paid.payment_date': 'Payment Date',
  'text.cloverecommerce.paid..placeholder.search': 'Search..',

  'text.cloverecommerce.unpaid.sno': 'S.No',
  'text.cloverecommerce.unpaid.invoice_no': 'Invoice Number',
  'text.cloverecommerce.unpaid.storename': 'Store Name',
  'text.cloverecommerce.unpaid.invoice_amount': 'Invoice Amount',
  'text.cloverecommerce.unpaid.invoice_date': 'Invoice Date',
  'text.cloverecommerce.unpaid.payment_status': 'Payment Status',
  'text.cloverecommerce.unpaid.payment_method': 'Payment Method',
  'text.cloverecommerce.unpaid.payment_date': 'Payment Date',
  'text.cloverecommerce.unpaid..placeholder.search': 'Search..',

  'text.invoice.poyntbilling': 'Poynt Billing',
  'text.invoice.bamborabillingbilling': 'Bambora Billing',

  'text.receiptsettings': 'Receipt Settings',
  'text.preferences': 'Preferences',
  'form.label.printreceipt': 'Print receipt after the order is completed?',
  'form.label.printmerchantcopy': 'Print merchant copy after the order is completed?',
  'form.label.footerline': 'Footer Line Text',
  'form.error.footerline.required': 'Please enter the text',
  'form.placeholder.footerline': 'Please enter the footer line text',
  'form.label.idletimeout': 'Idle Timeout (In seconds)',
  'form.error.idletimeout.required': 'Idle timeout required',
  'form.placeholder.idletimeout': '0 means disabled',
  'form.label.tipamount': 'Tip Options',
  'form.label.tip': 'Tip',
  'form.label.tipoptions': 'Choose exactly 3 tip options',

  'form.placeholder.message': 'Type your notes here',

  'text.active': 'Active',
  'text.inactive': 'Inactive',
  'text.demo': 'Demo',
  'text.viewactivitylog': 'Activity Log',
  'text.removeuser.description': 'Are you sure you want to remove the user?',

  'form.placeholder.search': 'Search..',

  'text.stafflist': 'Manage Users',
  'text.totalCount': '{count, plural, =1{1 record} other{# records}}',
  'text.editstaff': 'Update User',

  'text.viewstaff.sno': 'S.No.',
  'text.viewstaff.name': 'Name',
  'text.viewstaff.email': 'Email',
  'text.viewstaff.mobile': 'Phone Number',
  'text.viewstaff.role': 'Role',
  'text.viewstaff.status': 'Status',
  'text.viewstaff.action': 'Action',
  'text.removeUser': 'Remove {name}',
  'text.createstaff': 'Create User',
  'action.createstaff': 'Create User',
  'form.label.role': 'Role',
  'form.label.name': 'Contact Name',
  'form.label.intersection': 'Intersection',
  'form.label.mobile': 'Phone Number',
  'form.label.reseller': 'Reseller',
  'form.placeholder.role': 'Select the role',
  'form.placeholder.name': 'Enter the name',
  'form.placeholder.intersection': 'Enter the Intersection',
  'form.placeholder.mobile': '(123) 456-7890',
  'form.placeholder.password': 'Enter the password',
  'form.placeholder.reseller': 'Select the Reseller',
  'action.create': 'Create',
  'form.label.timezone': 'Timezone',
  'form.error.timezone.required': 'Please select the timezone',
  'form.placeholder.timezone': 'Select the timezone',

  'text.changepayment': 'Change Payment Method',
  'form.label.changepayment': 'Payment Method',
  'form.placeholder.changepayment': 'Select payment method',
  'text.billingtype': 'Payment Method',
  'form.label.cardNumber': 'Card Number',
  'form.label.creditCard': 'Credit Card',
  'form.label.cvv': 'CVV',
  'form.label.zipPostalCode': 'Zip/Postal Code',
  'form.placeholder.zipcode': 'Zip/Postal Code',
  'form.placeholder.cardName': 'Name on card',
  'form.label.cardname': 'Name',
  'form.error.required': 'Required',
  'text.billingtype.sno': 'S.No.',
  'text.billingtype.card': 'Payment Method',
  'text.billingtype.action': 'Action',

  'action.resendWelcomeEmail': 'Resend Activation Key',
  'topBar.profileMenu.logout': 'Logout',
  'text.copyright': 'Copyright © {copyrighttext}. All rights reserved.',
  'text.copyrightInnerpage': 'Copyright © {copyrighttext}.',
  'text.signIn.title': 'Sign in to your account',
  'text.dontHaveAccount': "Don't have an account?",
  'action.signUp': 'Sign Up',
  'action.next': 'Next',
  'action.gotosignin': 'Go to Sign in',
  'action.forgotPassword': 'Forgot password?',
  'action.signIn': 'Sign in',
  'form.label.password': 'Password',
  'form.error.password.max': 'Password must be up to 10 characters',
  'form.error.password.min': 'Password must be at least 8 characters',
  'form.error.password.required': 'Please input the password',
  'form.error.email.required': 'Please input the email address',
  'form.error.email.type': 'Invalid email address',
  'form.error.email.max': 'Email address must be up to 25 characters',
  'form.placeholder.email': 'Enter the email',
  'form.label.email': 'Email Id',
  'form.label.active': 'Active',
  'form.label.inactive': 'Inactive',
  'form.placeholder.company': 'Company Name',
  'form.label.company': 'Company Name',
  'form.error.empty.select': 'No matches found.',
  'form.placeholder.province': 'Enter the Province/State',
  'form.placeholder.country': 'Enter the Country',
  'desc.logo': 'Logo Image',
  'title.helmet.orderinfo': 'Order Summary',
  'title.helmet.devicelogs': 'Device Info',
  'text.stacktrace': 'Stacktrace',
  'text.message': 'Message',
  'text.changepayment.description': ' ',
  'text.notes.button_message': 'Add Notes ',
  'form.storedetail.label.status': 'Account Status',
  'form.user.placeholder.search': 'Search by name or email or role',
  'form.label.agent_id': 'Agent ID',
  'text.resetpassword': 'Recover Password',
  'text.resetbutton': 'Reset',
  'title.recoverPassword': 'Recover Password',
  'action.recoverPassword': 'Recover my password',
  'title.resetPassword': 'Reset Password',
  'form.label.password.new': 'New Password',
  'form.label.password.confirm': 'Confirm Password',
  'action.resetPassword': 'Reset my password',
  'form.error.password.match': "Passwords doesn't match",
  'text.alreadyHaveAnAccount': 'Already have an account?',
  'action.Signin': 'Sign in',
  'text.reselleragent': ' Reseller Agent',
  'form.label.pos_software': 'Restaurant Software',
  'form.placeholder.storeinfo.pos_software': 'Restaurant Software',
  'form.placeholder.pos_software': 'Enter the Restaurant Software',
  'form.error.phone.invalid': 'Invalid phone number',
  'form.placeholder.agent_id': 'Agent ID',
  'text.custommessage.openorder': 'No open orders to show',
  'text.custommessage.closedorder': 'No closed orders to show',
  'text.custommessage.windowsclient': 'No windows client to show',
  'text.custommessage.paymentterminal': 'No payment terminal to show',
  'text.custommessage.activestores': 'No active stores to show',
  'text.custommessage.inactivestores': 'No Inactive stores to show',
  'text.custommessage.demostores': 'No Demo account to show',
  'text.custommessage.userinfo': 'No users to show',
  'action.viewReceipt': 'View Receipt',
  'form.label.select.restaurant_software': ' ',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
