import { createSelector } from 'reselect'

export const selectCountry = state => state.country

export const selectUsers = state => state.user

export const selectActiveStores = state => state.storeinfo

export const selectSOftwareList = state => state.softwarelist

export const selectActiveStoreListData = createSelector([selectActiveStores], storeinfo => {
  if (storeinfo.searchactive && storeinfo.storelist) {
    const activestoresearch = storeinfo.searchactive.toLowerCase()
    return storeinfo.storelist.filter(
      o =>
        o.name?.toLowerCase()?.includes(activestoresearch) ||
        o.reseller?.company?.toLowerCase()?.includes(activestoresearch) ||
        o.reseller?.name?.toLowerCase()?.includes(activestoresearch) ||
        o.user?.email?.toLowerCase()?.includes(activestoresearch),
    )
  }
  return storeinfo.storelist
})

export const selectInActiveStores = state => state.storeinfo

export const selectInctiveStoreListData = createSelector([selectInActiveStores], storeinfo => {
  if (storeinfo.searchinactive && storeinfo.storelist) {
    const inactivestoresearch = storeinfo.searchinactive.toLowerCase()
    return storeinfo.storelist.filter(
      o =>
        o.name?.toLowerCase()?.includes(inactivestoresearch) ||
        o.reseller?.company?.toLowerCase()?.includes(inactivestoresearch) ||
        o.reseller?.name?.toLowerCase()?.includes(inactivestoresearch) ||
        o.user?.email?.toLowerCase()?.includes(inactivestoresearch),
    )
  }
  return storeinfo.storelist
})

export const selectDemoStores = state => state.storeinfo

export const selectDemoStoreListData = createSelector([selectDemoStores], storeinfo => {
  if (storeinfo.searchdemo && storeinfo.storelist) {
    const demostoresearch = storeinfo.searchdemo.toLowerCase()
    return storeinfo.storelist.filter(
      o =>
        o.name?.toLowerCase()?.includes(demostoresearch) ||
        o.reseller?.company?.toLowerCase()?.includes(demostoresearch) ||
        o.reseller?.name?.toLowerCase()?.includes(demostoresearch) ||
        o.user?.email?.toLowerCase()?.includes(demostoresearch),
    )
  }
  return storeinfo.storelist
})
