import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import moment from 'moment-timezone'
import Loading from 'components/app/common/Loading'

const mapStateToProps = ({ dispatch, order }) => ({
  dispatch,
  order,
})

const CloseOrderSummary = ({ order, intl: { formatMessage } }) => {
  if (!order.selectedDatatwo) return <Loading />

  const length = order.selectedDatatwo.payments?.length || 0

  const openReceipt = url => {
    window.open(url)
  }

  return (
    <div>
      <div className="card">
        <div className="card-body p-2">
          <div className="d-flex flex-wrap m-1">
            <div className="flex-grow-1">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.orderno' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                {order.selectedDatatwo?.order_number}
              </div>
            </div>
            <div className="flex-grow-1 text-right">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.ordercreatedon' })}
                {moment(order.selectedDatatwo?.created_at).format(' [On] MMM D, YYYY hh:mm a')}
              </div>
              <div className="font-size-12 text-gray-5">
                <></>
              </div>
            </div>
          </div>

          <div className="d-flex m-2">
            <div className="flex-grow-1">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.ordertableno' })}
              </div>
              <div
                className="font-size-16 font-weight-bold"
                hidden={order.selectedDatatwo?.table_number === ''}
              >
                {order.selectedDatatwo.table_number}
              </div>
              <div
                className="font-size-16 font-weight-bold"
                hidden={order.selectedDatatwo?.table_number !== ''}
              >
                -
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.orderseatno' })}
              </div>
              <div
                className="font-size-16 font-weight-bold"
                hidden={order.selectedDatatwo?.seat_number === ''}
              >
                {order.selectedDatatwo?.seat_number}
              </div>
              <div
                className="font-size-16 font-weight-bold"
                hidden={order.selectedDatatwo?.seat_number !== ''}
              >
                -
              </div>
            </div>
            <div className="flex-grow-1 text-right">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.orderusername' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                {order.selectedDatatwo?.staff?.name}
              </div>
            </div>
          </div>

          <div className="d-flex m-2">
            <div className="flex-grow-1">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.ordertype' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                {order.selectedDatatwo?.order_type}
              </div>
            </div>
            <div className="flex-grow-1 text-right">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.orderpaymentstatus' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                <span className="custom_theme_color">{order.selectedDatatwo.payment_status}</span>
              </div>
            </div>
          </div>
          <div className="d-flex m-2">
            <div className="flex-grow-1" hidden={length > 1}>
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.ordertransactiontype' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                {order.selectedDatatwo.transaction_type}
              </div>
            </div>
            <div className="flex-grow-1 text-right" hidden={length > 1}>
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.orderpaymentmethod' })}
              </div>
              {order.selectedDatatwo.payments[0].payment_type !== 'Cash' && (
                <div className="font-size-16 font-weight-bold">
                  {order.selectedDatatwo?.payments[0].card_mode} -{' '}
                  {order.selectedDatatwo?.payments[0].card_type}
                  {' ('}
                  {order.selectedDatatwo?.payments[0].account_number}
                  {')'}
                </div>
              )}
              {order.selectedDatatwo.payments[0].payment_type === 'Cash' && (
                <div className="font-size-16 font-weight-bold">
                  {order.selectedDatatwo?.payments[0].payment_type}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex m-2">
            <div className="flex-grow-1" hidden={length > 1}>
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.orderauthnumber' })}
              </div>
              {order.selectedDatatwo.payments[0].payment_type !== 'Cash' && (
                <div className="font-size-16 font-weight-bold">
                  {order.selectedDatatwo?.payments[0].auth_number}
                </div>
              )}
              {order.selectedDatatwo.payments[0].payment_type === 'Cash' && (
                <div className="font-size-16 font-weight-bold">-</div>
              )}
            </div>

            <div className="flex-grow-1 text-right" hidden={length > 1}>
              <div className="font-size-12 text-gray-5">
                {' '}
                {formatMessage({ id: 'text.ordertransactiondate' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                {moment
                  .tz(order.selectedDatatwo?.payments[0].transaction_at, 'America/Los_Angeles')
                  .format(' MMM D, YYYY hh:mm a')}
              </div>
            </div>
          </div>

          <div className="d-flex m-2">
            <div className="flex-grow-1 text-left" hidden={length > 1}>
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.ordertip' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                <span
                  className={`${
                    order.payment_status === 'Not Paid' ? 'text-danger' : 'custom_theme_color'
                  }`}
                >
                  ${order.selectedDatatwo.tip.toFixed(2)}
                </span>
              </div>
            </div>
            <div className="flex-grow-1 text-right" hidden={length > 1}>
              <div className="font-size-12 text-gray-5">
                {order.selectedDatatwo.tip.toFixed(2) !== '0.00' && (
                  <span>{formatMessage({ id: 'text.orderamountpaidwithtip' })}</span>
                )}
                {order.selectedDatatwo.tip.toFixed(2) === '0.00' && (
                  <span>{formatMessage({ id: 'text.orderamountpaid' })}</span>
                )}
              </div>
              <div className="font-size-16 font-weight-bold custom_theme_color">
                ${order.selectedDatatwo.order_total.toFixed(2)}
              </div>
            </div>
          </div>
          {order.selectedDatatwo.payments[0].payment_type === 'Card' && (
            <div className="d-flex m-2">
              <div className="flex-grow-1 text-right" hidden={length > 1}>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => openReceipt(order.selectedDatatwo?.payments[0].receipt_url)}
                >
                  <i className="fe fe-file-text mr-2" />
                  {formatMessage({ id: 'action.viewReceipt' })}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {console.log(order.selectedDatatwo.payments)}

      {order.selectedDatatwo.payments.map(item => (
        <div style={{ display: `${length <= 1 ? 'none' : 'block'}` }}>
          <div className="card" key={item.payment_type} name={item.payment_amount}>
            <div className="card-body p-2">
              <div className="d-flex m-2">
                <div className="flex-grow-1">
                  <div className="font-size-12 text-gray-5">
                    {formatMessage({ id: 'text.ordertransactiontype' })}
                  </div>
                  <div className="font-size-16 font-weight-bold">
                    {order.selectedDatatwo.transaction_type}
                  </div>
                </div>
                <div className="flex-grow-1 text-right">
                  <div className="font-size-12 text-gray-5">
                    {formatMessage({ id: 'text.orderpaymentmethod' })}
                  </div>
                  <div
                    className="font-size-16 font-weight-bold"
                    hidden={item.payment_type === 'Cash'}
                  >
                    {item.card_mode} - {item.card_type}
                    {' ('}
                    {item.account_number}
                    {')'}
                  </div>
                  <div
                    className="font-size-16 font-weight-bold"
                    hidden={item.payment_type !== 'Cash'}
                  >
                    {item.payment_type}
                  </div>
                </div>
              </div>
              <div className="d-flex m-2">
                <div className="flex-grow-1" hidden={item.payment_type === 'Cash'}>
                  <div className="font-size-12 text-gray-5">
                    {formatMessage({ id: 'text.orderauthnumber' })}
                  </div>
                  <div className="font-size-16 font-weight-bold">{item.auth_number}</div>
                </div>
                <div className="flex-grow-1" hidden={item.payment_type !== 'Cash'}>
                  <div className="font-size-12 text-gray-5">
                    {formatMessage({ id: 'text.orderauthnumber' })}
                  </div>
                  <div className="font-size-16 font-weight-bold">-</div>
                </div>

                <div className="flex-grow-1 text-right">
                  <div className="font-size-12 text-gray-5">
                    {' '}
                    {formatMessage({ id: 'text.ordertransactiondate' })}
                  </div>
                  <div className="font-size-16 font-weight-bold">
                    {moment
                      .tz(item.transaction_at, 'America/Los_Angeles')
                      .format(' MMM D, YYYY hh:mm a')}
                  </div>
                </div>
              </div>
              <div className="d-flex m-2">
                <div className="flex-grow-1 text-left">
                  <div className="font-size-12 text-gray-5">
                    {formatMessage({ id: 'text.ordertip' })}
                  </div>
                  <div className="font-size-16 font-weight-bold">
                    <span className="custom_theme_color">${item.tip.toFixed(2)}</span>
                  </div>
                </div>
                <div className="flex-grow-1 text-right">
                  <div className="font-size-12 custom_theme_color">
                    {item.tip.toFixed(2) !== '0.00' && (
                      <span>{formatMessage({ id: 'text.orderamountpaidwithtip' })}</span>
                    )}
                    {item.tip.toFixed(2) === '0.00' && (
                      <span>{formatMessage({ id: 'text.orderamountpaid' })}</span>
                    )}
                  </div>
                  <div className="font-size-16 font-weight-bold custom_theme_color">
                    ${item.payment_amount.toFixed(2)}
                  </div>
                </div>
              </div>

              {item.payment_type !== 'Cash' && (
                <div className="d-flex m-2">
                  <div className="flex-grow-1 text-right">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => openReceipt(item?.receipt_url)}
                    >
                      <i className="fe fe-file-text mr-2" />
                      {formatMessage({ id: 'action.viewReceipt' })}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(CloseOrderSummary))
