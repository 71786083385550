/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  data: null,

  displayLogDrawer: false,
  creating: false,
  selectedData: null,

  displayRemoveModal: false,
  displayRetryPaymentModal: false,
  displayUpdatePaymentModal: false,
  displayVoidInvoiceModal: false,
  displayActivityDrawer: false,
  displayChangePaymentModal: false,
  removing: false,
}

export default function invoicesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.VIEW_INVOICE:
      return { ...state, displayLogDrawer: true }

    case actions.VIEW_ACTIVITY:
      return { ...state, displayActivityDrawer: true }

    case actions.SHOW_RESEND:
      return { ...state, selectedData: action.payload.invoices, displayRemoveModal: true }
    case actions.HIDE_RESEND:
      return { ...state, displayRemoveModal: false }

    case actions.SHOW_RETRY:
      return { ...state, selectedData: action.payload.invoices, displayRetryPaymentModal: true }
    case actions.HIDE_RETRY:
      return { ...state, displayRetryPaymentModal: false }
    case actions.SHOW_CHANGE:
      return { ...state, selectedData: action.payload.invoices, displayChangePaymentModal: true }
    case actions.HIDE_CHANGE:
      return { ...state, displayChangePaymentModal: false }

    case actions.SHOW_UPDATE_PAYMENT:
      return { ...state, selectedData: action.payload.invoices, displayUpdatePaymentModal: true }
    case actions.HIDE_UPDATE_PAYMENT:
      return { ...state, displayUpdatePaymentModal: false }

    case actions.SHOW_VOID_INVOICE:
      return { ...state, selectedData: action.payload.invoices, displayVoidInvoiceModal: true }
    case actions.HIDE_VOID_INVOICE:
      return { ...state, displayVoidInvoiceModal: false }

    default:
      return state
  }
}
