import apiClient from 'services/axios'

const BASE_URL = 'invoices'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getinvoice(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
