/* eslint-disable */
import React, { useState, useEffect, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import StoreInfoAction from 'redux/storeinfo/actions'
import { createStructuredSelector } from 'reselect'
import {
  selectWindowsDevice,
  selectCloverDevice,
  selectWindowsDeviceData,
  selectCloverDeviceData,
} from 'redux/storeinfo/deviceselectors'
import { selectSettings } from 'redux/settings/selectors'
import Logsinfo from '../../LogInfo'
import { Input, Table, Form, Drawer, Typography, Spin, Modal, Space } from 'antd'

const { Search } = Input

const { Text } = Typography

const mapStateToProps = () =>
  createStructuredSelector({
    deviceone: selectWindowsDevice,
    devicetwo: selectCloverDevice,
    deviceDataone: selectWindowsDeviceData,
    deviceDatatwo: selectCloverDeviceData,
    settings: selectSettings,
  })

const WindowsClient = ({
  settings,
  deviceone,
  deviceDataone,
  dispatch,
  intl: { formatMessage },
}) => {
  const [page, setPage] = useState(1)

  const selectedServerValue = 'Server'
  const selectedClientValue = 'Client'
  const filterWindowsArray = deviceDataone?.filter(
    data => data.device_type === selectedServerValue || data.device_type === selectedClientValue,
  )

  const path = window.location.pathname
  const userId = path.replace('/manage/store-info/', '')

  useEffect(() => {
    dispatch({
      type: StoreInfoAction.LOAD_DEVICES,
      payload: userId,
    })
  }, [dispatch, userId])

  const { confirm } = Modal

  const showModal = value1 => {
    confirm({
      title:
        'Are you sure you want to stop the service?. Once stoppped you cannot revert back the service.',
      onOk: () => {
        dispatch({
          type: StoreInfoAction.STOP_SERVICE,
          payload: {
            device_id: value1?._id,
            status: 'Inactive',
          },
        })
      },
      okText: 'Yes. Go Head',
      cancelText: 'Cancel',
    })
  }

  const showDrawer = deviceRecord => {
    dispatch({
      type: StoreInfoAction.SHOW_CREATE,
      payload: {
        devices: deviceRecord,
      },
    })
  }

  const hideDrawer = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displayLogDrawer: false,
      },
    })
  }

  const onSearch = value => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: { searchwindows: value },
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.windowsclient' }),
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.deviceinfo.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.deviceinfo.devicename' }),
      dataIndex: 'device_name',
      key: 'device_name',
      // render: (text, record) => (
      //   <>
      //     {record.socket_status === true && <Badge size="large" color="green" />}
      //     {record.socket_status === false && <Badge size="large" color="red" />}

      //     {record.device_name}
      //   </>
      // ),
    },
    {
      title: formatMessage({ id: 'text.deviceinfo.ipaddress' }),
      dataIndex: 'ip_address',
      key: 'ip_address',
    },
    {
      title: formatMessage({ id: 'text.deviceinfo.macaddress' }),
      dataIndex: 'mac_address',
      key: 'mac_address',
    },
    {
      title: formatMessage({ id: 'text.deviceinfo.devicetype' }),
      dataIndex: 'device_type',
      key: 'device_type',
    },
    {
      title: formatMessage({ id: 'text.deviceinfo.servicestatus' }),
      dataIndex: 'server_status',
      key: 'server_status',
      render: (text, record) => (
        <>
          {record.server_status === 'Running' && (
            <span className="text-success">
              {formatMessage({ id: 'text.deviceinfo.servicestatusrunning' })}
            </span>
          )}

          {record.server_status === 'Stopped' && (
            <span className="text-danger">
              {formatMessage({ id: 'text.deviceinfo.servicestatusstopped' })}
            </span>
          )}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.userinfo.action' }),
      key: 'action',
      render: (text, record) => (
        <>
          {record.status === 'Active' && (
            <>
              <Space size="middle">
                <i
                  className="fe fe-settings mr-1"
                  title="Stop the service"
                  aria-hidden
                  onClick={() => showModal(text)}
                />
              </Space>
            </>
          )}
          {record.status === 'Inactive' && <>-</>}
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.deviceinfo.logs' }),
      dataIndex: 'id',
      render: text => (
        <>
          <i className="fe fe-file-text mr-1" onClick={() => showDrawer(text)} aria-hidden="true" />
        </>
      ),
    },
  ]

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%' }}
              placeholder={formatMessage({ id: 'form.windowsclient.placeholder.search' })}
              size="large"
              allowClear
              onSearch={onSearch}
            />
          </Form.Item>
        </div>
      </div>
      <Suspense fallback={<Spin />}>
        {filterWindowsArray?.length >= 0 ? (
          <Table
            dataSource={filterWindowsArray}
            columns={columns}
            locale={locale}
            rowKey="_id"
            pagination={{
              onChange(current) {
                setPage(current)
              },
            }}
          />
        ) : (
          <Table
            columns={columns}
            locale={locale}
            loading={{
              indicator: (
                <div>
                  <Spin />
                </div>
              ),
            }}
          />
        )}
      </Suspense>
      <Drawer
        title={formatMessage({ id: 'text.devicelogs' })}
        placement="right"
        destroyOnClose
        visible={deviceone.displayLogDrawer}
        onClose={hideDrawer}
        width={settings.isMobileView ? '100%' : '44%'}
      >
        <Logsinfo />
      </Drawer>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(WindowsClient))
