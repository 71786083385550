import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import StoreInfoAction from 'redux/storeinfo/actions'
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment'
import Loading from 'components/app/common/Loading'
import { injectIntl } from 'react-intl'

const mapStateToProps = (devices, dispatch) => ({ devices, dispatch })

const Logsinfo = ({ devices, dispatch, intl: { formatMessage } }) => {
  const windowsdeviceid = devices?.storeinfo?.selectedWindowsDevice

  const logsinfo = devices?.storeinfo?.logsdata

  useEffect(() => {
    dispatch({
      type: StoreInfoAction.LOAD_LOGS,
      payload: windowsdeviceid,
    })
  }, [dispatch, windowsdeviceid])

  const length = logsinfo?.length || 0

  if (!logsinfo) return <Loading />

  return (
    <div>
      <div className="card" hidden={length === 0}>
        <div className="card-body">
          <div className="height-600 d-flex flex-column justify-content-center">
            <PerfectScrollbar>
              {logsinfo.map(item => (
                <div
                  className="row"
                  style={{ marginBottom: '20px' }}
                  key={item.id}
                  name={item.stacktrace}
                >
                  <div className="col-md-12">
                    <div className="d-flex flex-wrap m-1">
                      <div className="flex-grow-1">
                        <div
                          className="text-center font-weight-normal"
                          style={{ fontSize: '10px' }}
                        >
                          {moment(Number(item?.date_time)).format(' [On] MMM D, YYYY hh:mm a')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex flex-wrap m-1">
                      <div className="flex-grow-1 text-left">
                        <div className="text-black-6" style={{ fontSize: '12px' }}>
                          {formatMessage({ id: 'text.stacktrace' })}&nbsp;&nbsp;:&nbsp;
                          {item?.stacktrace}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex flex-wrap m-1">
                      <div className="flex-grow-1 text-left">
                        <div className="font-size-12 text-black-6" style={{ fontSize: '12px' }}>
                          {formatMessage({ id: 'text.message' })}&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                          <span className="text-danger">
                            {'"'}
                            {item?.message}
                            {'"'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </PerfectScrollbar>
          </div>
        </div>
      </div>
      <div className="card" hidden={length !== 0}>
        <div className="card-body">
          <div className="height-600 d-flex flex-column justify-content-end">
            <div className="row" style={{ marginBottom: '20px' }}>
              <div className="col-md-12">
                <div className="d-flex flex-wrap m-1">
                  <div className="flex-grow-1">
                    <div
                      className="text-center font-weight-normal font-size-15 text-gray-5"
                      style={{ padding: '0 0 200px 0' }}
                    >
                      {formatMessage({ id: 'text.errorlogsmessage' })}
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(Logsinfo))
