import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Tabs } from 'antd'
import { createStructuredSelector } from 'reselect'
import {
  selectWindowsDevice,
  selectCloverDevice,
  selectWindowsDeviceData,
  selectCloverDeviceData,
} from 'redux/storeinfo/deviceselectors'
import { selectSettings } from 'redux/settings/selectors'
import WindowsClient from './WindowsClient'
import Paymentterminal from './Paymentterminal'

const { TabPane } = Tabs

const mapStateToProps = () =>
  createStructuredSelector({
    deviceone: selectWindowsDevice,
    devicetwo: selectCloverDevice,
    deviceDataone: selectWindowsDeviceData,
    deviceDatatwo: selectCloverDeviceData,
    settings: selectSettings,
  })

const TablesAntd = ({ deviceDataone, deviceDatatwo, intl: { formatMessage } }) => {
  const selectedServerValue = 'Server'
  const selectedClientValue = 'Client'
  const filterWindowsArray = deviceDataone?.filter(
    data => data.device_type === selectedServerValue || data.device_type === selectedClientValue,
  )

  const selectedCloverValue = 'Clover'

  const filterCloverArray = deviceDatatwo?.filter(data => data.device_type === selectedCloverValue)

  const query = new URLSearchParams(window.location.search)
  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'windows-client') {
    tabUrlVal = '1'
  } else if (tabname === 'payment-terminal') {
    tabUrlVal = '2'
  }

  return (
    <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: 'calc(7px - 1px)' }}>
      <Tabs defaultActiveKey={tabUrlVal}>
        <TabPane
          tab={
            <Link to="?tab=windows-client">
              <span>
                {formatMessage({ id: 'text.storeino.tab.windowsclient' })}
                {' ('}
                {formatMessage({ id: 'text.space' }, { count: filterWindowsArray?.length || 0 })}
                {')'}{' '}
              </span>
            </Link>
          }
          key={1}
        >
          <WindowsClient />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=payment-terminal">
              <span>
                {formatMessage({ id: 'text.storeino.tab.paymentterminal' })}
                {' ('}
                {formatMessage({ id: 'text.space' }, { count: filterCloverArray?.length || 0 })}
                {')'}{' '}
              </span>
            </Link>
          }
          key={2}
          onChange="onchange()"
        >
          <Paymentterminal />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(TablesAntd))
