const actions = {
  SET_STATE: 'invoices/SET_STATE',
  VIEW_INVOICE: 'invoices/VIEW_INVOICE',
  VIEW_ACTIVITY: 'invoices/VIEW_ACTIVITY',
  SHOW_RESEND: 'invoices/SHOW_RESEND',
  HIDE_RESEND: 'invoices/HIDE_RESEND',
  REMOVE: 'invoices/REMOVE',
  SHOW_RETRY: 'invoices/SHOW_RETRY',
  HIDE_RETRY: 'invoices/HIDE_RETRY',
  RETRY: 'invoices/RETRY',

  SHOW_CHANGE: 'invoices/SHOW_CHANGE',
  HIDE_CHANGE: 'invoices/HIDE_CHANGE',
  CHANGE: 'invoices/CHANGE',

  SHOW_UPDATE_PAYMENT: 'invoices/SHOW_UPDATE_PAYMENT',
  HIDE_UPDATE_PAYMENT: 'invoices/HIDE_UPDATE_PAYMENT',
  UPDATE_PAYMENT: 'invoices/UPDATE_PAYMENT',

  SHOW_VOID_INVOICE: 'invoices/SHOW_VOID_INVOICE',
  HIDE_VOID_INVOICE: 'invoices/HIDE_VOID_INVOICE',
  VOID_INVOICE: 'invoices/VOID_INVOICE',

  LOAD_ALL: 'invoices/LOAD_ALL',
}

export default actions
