import { all, put, call, takeLatest, select } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import actions from './actions'
import { selectUserData } from '../user/selectors'

export function* SET_DATA() {
  const menuData = yield call(getMenuData)
  const UserData = yield select(selectUserData)
  if (!UserData) menuData.splice(menuData.map(o => o.key).indexOf('manage-user'))
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([takeLatest(actions.SET_DATA, SET_DATA), SET_DATA()])
}
