import apiClient from 'services/axios'

const BASE_URL = 'order'

export async function loadopenorder(Userid) {
  return apiClient
    .get(`${BASE_URL}/openorder/${Userid}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadclosedorder(userId, payload) {
  const dateValue = payload.datevalue
  return apiClient
    .get(`${BASE_URL}/closedorder/${userId}/${dateValue}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadclosedorderreport(userId, payload) {
  const dateValue = payload.values
  return apiClient
    .get(`${BASE_URL}/closedorder/${userId}/${dateValue}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
