import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import moment from 'moment'
import OrderAction from 'redux/order/actions'
import { createStructuredSelector } from 'reselect'
import { selectOrder, selectOrderData } from 'redux/storeinfo/openorderselectors'
import { selectCloseOrder, selectCloseOrderData } from 'redux/storeinfo/closeorderselectors'
import { selectSettings } from 'redux/settings/selectors'
import { Tabs } from 'antd'
import OpenOrder from './OpenOrder'
import CloseOrder from './CloseOrder'

const { TabPane } = Tabs

const mapStateToProps = () =>
  createStructuredSelector({
    orderone: selectOrder,
    orderDataone: selectOrderData,
    ordertwo: selectCloseOrder,
    orderDatatwo: selectCloseOrderData,
    settings: selectSettings,
  })

const Orders = ({ dispatch, orderDataone, orderDatatwo, intl: { formatMessage } }) => {
  const [valueone] = useState(new Date())

  const datevalue = moment(valueone).format('YYYY-MM-DD')

  const path = window.location.pathname

  const idval = path.replace('/manage/store-info/', '')

  useEffect(() => {
    dispatch({
      type: OrderAction.LOAD_REPORT,
      payload: {
        idval,
        datevalue,
      },
    }) // eslint-disable-next-line
  }, [dispatch])

  const query = new URLSearchParams(window.location.search)
  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'open-order') {
    tabUrlVal = '1'
  } else if (tabname === 'closed-order') {
    tabUrlVal = '2'
  }

  return (
    <div>
      <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: 'calc(7px - 1px)' }}>
        <Tabs defaultActiveKey={tabUrlVal}>
          <TabPane
            tab={
              <Link to="?tab=open-order">
                <span>
                  {formatMessage({ id: 'text.storeino.tab.openorder' })}
                  {' ('}
                  {formatMessage({ id: 'text.space' }, { count: orderDataone?.length || 0 })}
                  {')'}
                </span>
              </Link>
            }
            // tab={
            //   <span>
            //     {formatMessage({ id: 'text.storeino.tab.openorder' })}
            //     {' ('}
            //     {formatMessage({ id: 'text.space' }, { count: orderDataone?.length || 0 })}
            //     {')'}
            //   </span>
            // }
            key={1}
          >
            <OpenOrder />
          </TabPane>
          <TabPane
            // tab={
            //   <span>
            //     {formatMessage({ id: 'text.storeino.tab.coseorder' })}
            //     {' ('}
            //     {formatMessage({ id: 'text.space' }, { count: orderDatatwo?.length || 0 })}
            //     {')'}
            //   </span>
            // }
            tab={
              <Link to="?tab=closed-order">
                <span>
                  {formatMessage({ id: 'text.storeino.tab.coseorder' })}
                  {' ('}
                  {formatMessage({ id: 'text.space' }, { count: orderDatatwo?.length || 0 })}
                  {')'}{' '}
                </span>
              </Link>
            }
            key={2}
            onChange="onchange()"
          >
            <CloseOrder />
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(Orders))
