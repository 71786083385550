import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import StoreInfoAction from 'redux/storeinfo/actions'
import { createStructuredSelector } from 'reselect'
import {
  selectActiveStores,
  selectActiveStoreListData,
  selectUsers,
} from 'redux/storeinfo/storelistselectors'
import { selectSettings } from 'redux/settings/selectors'
import { Tabs, Tag } from 'antd'
import Loading from 'components/app/common/Loading'
import StoreDetail from '../StoreDetail'
import DeviceInfo from '../DeviceInfo'
import Orders from '../Orders'
// import PaymentHistory from '../PaymentHistory'
// import SubscriptionInfo from '../SubscriptionInfo'
import Notes from '../Notes'

const { TabPane } = Tabs

const mapStateToProps = () =>
  createStructuredSelector({
    usersdata: selectUsers,
    stores: selectActiveStores,
    storeListData: selectActiveStoreListData,
    settings: selectSettings,
  })

const StoreInfo = ({ dispatch, stores, usersdata, intl: { formatMessage } }) => {
  const user_permission = usersdata?.data?.supportuser?.permission

  const path = window.location.pathname

  const idval = path.replace('/manage/store-info/', '')

  useEffect(() => {
    if (idval !== null)
      dispatch({
        type: StoreInfoAction.LOAD_INFO,
        payload: idval,
      }) // eslint-disable-next-line
  }, [dispatch])

  if (!stores) return <Loading />

  const query = new URLSearchParams(window.location.search)
  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'store-info') {
    tabUrlVal = '1'
  } else if (tabname === 'windows-client') {
    tabUrlVal = '3'
  } else if (tabname === 'payment-terminal') {
    tabUrlVal = '3'
  } else if (tabname === 'notes') {
    tabUrlVal = '5'
  } else if (tabname === 'open-order') {
    tabUrlVal = '6'
  } else if (tabname === 'closed-order') {
    tabUrlVal = '6'
  }

  return (
    <div>
      <Helmet
        title={formatMessage({
          id: 'title.helmet.store',
        })}
      />
      <div className="row">
        <div className="col-md-6">
          <h5 className="mb-4">
            <strong>{stores?.data1}</strong>
            &nbsp;
            {stores?.data16 === 'Active' && <Tag color="green">{stores?.data16}</Tag>}
            {stores?.data16 === 'Inactive' && <Tag color="red">{stores?.data16}</Tag>}
            {stores?.data16 === 'Demo' && <Tag color="blue">{stores?.data16}</Tag>}
          </h5>
        </div>
        {stores?.data17 && (
          <div className="col-md-6">
            <h5 className="mb-4" style={{ float: 'right' }}>
              <strong>
                {stores?.data17}
                {' - '}
                {stores?.data18}
                {'      '}
              </strong>
              <Tag color="magenta" style={{ textTransform: 'capitalize' }}>
                {formatMessage({ id: 'text.reselleragent' })}
              </Tag>
            </h5>
          </div>
        )}
      </div>

      <Tabs defaultActiveKey={tabUrlVal}>
        {user_permission?.read_store_info === true && (
          <TabPane
            tab={
              <Link to="?tab=store-info">
                <span>{formatMessage({ id: 'text.storeino.tab.storeinfo' })}</span>
              </Link>
            }
            key={1}
          >
            <StoreDetail />
          </TabPane>
        )}
        {/* {user_permission?.read_subscription === true && (
          <TabPane
            tab={<span>{formatMessage({ id: 'text.storeino.tab.subscriptioninfo' })}</span>}
            key="2"
            onChange="onchange()"
          >
            <SubscriptionInfo />
          </TabPane>
        )} */}
        {user_permission?.read_device_info === true && (
          <TabPane
            tab={
              <Link to="?tab=windows-client">
                <span>{formatMessage({ id: 'text.storeino.tab.deviceinfo' })}</span>
              </Link>
            }
            key={3}
            onChange="onchange()"
          >
            <DeviceInfo />
          </TabPane>
        )}
        {/* {user_permission?.read_payment_history === true && (
          <TabPane
            tab={<span>{formatMessage({ id: 'text.storeino.tab.paymenthistory' })}</span>}
            key="4"
          >
            <PaymentHistory />
          </TabPane>
        )} */}
        {user_permission?.read_notes === true && (
          <TabPane
            // tab={<span>{formatMessage({ id: 'text.storeino.tab.notes' })}</span>}
            tab={
              <Link to="?tab=notes">
                <span>{formatMessage({ id: 'text.storeino.tab.notes' })}</span>
              </Link>
            }
            key={5}
            onChange="onchange()"
          >
            <Notes />
          </TabPane>
        )}
        {user_permission?.read_orders === true && (
          <TabPane
            tab={
              <Link to="?tab=open-order">
                <span>{formatMessage({ id: 'text.storeino.tab.orders' })}</span>
              </Link>
            }
            key={6}
            onChange="onchange()"
          >
            <Orders />
          </TabPane>
        )}
      </Tabs>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreInfo))
