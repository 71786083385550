import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadAll } from 'api/invoices'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { creditcarddata } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: creditcarddata,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD_ALL, LOAD_ALL)])
}
