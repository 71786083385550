import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import UserAction from 'redux/user/actions'
import { store as reduxStore } from 'index'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
})

apiClient.interceptors.request.use(request => {
  request.params = request.params || {}
  const accessToken = store.get('user.accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  const business = store.get('user.business')
  if (business) {
    request.params.business = business.code
  }
  const businessLocation = store.get('user.businessLocation')
  if (businessLocation) {
    request.params.business_location = businessLocation.code
  }
  return request
})

apiClient.interceptors.response.use(
  response => {
    if (response?.data?.message != null) {
      const { message } = response.data
      notification.success({
        message,
      })
    }
    return response
  },
  error => {
    if (error?.response?.status === 401) {
      reduxStore.dispatch({
        type: UserAction.CLEAR_USER,
      })
    } else if (error?.response?.data != null) {
      const { message } = error.response.data
      notification.error({
        message,
      })
    }
    throw error
  },
)

export default apiClient
