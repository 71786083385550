import { createSelector } from 'reselect'

export const selectOrder = state => state.order

export const selectActiveStores = state => state.storeinfo

export const selectOrderData = createSelector([selectOrder], order => {
  if (order.searchopenorder && order.openorderdata) {
    const opensearch = order.searchopenorder.toLowerCase()
    return order.openorderdata.filter(
      o =>
        o.order_number?.includes(opensearch) || o.staff?.name.toLowerCase()?.includes(opensearch),
    )
  }
  return order.openorderdata
})
