import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Badge, Dropdown, Menu } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const [count, setCount] = useState(0)

  const userActiveLogin = user?.data?.first_name

  const userRole = user?.data?.supportuser?.role

  const TablePay = 'Table Pay'

  const userActiveBusiness = user?.data?.supportuser?.company

  const userSoftware = user?.data?.supportuser?.restaurant_software

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const addCount = () => {
    // setCount(count + 1)
    setCount(count)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <i className="fe fe-user mr-2" />
        {userActiveLogin}
      </Menu.Item>
      {/* {renderImageContainer} */}
      <Menu.Item>
        <i className="fe fe-users mr-2" />
        {userRole === 'support admin' && <span>Support Admin</span>}
        {userRole === 'support agent' && <span>Support Agent</span>}
        {userRole === 'reseller admin' && <span>Reseller Admin</span>}
        {userRole === 'reseller agent' && <span>Reseller Agent</span>}
        {userRole === 'partner admin' && <span>Partner Admin</span>}
      </Menu.Item>
      {userRole === 'support admin' && (
        <Menu.Item>
          <i className="fe fe-shopping-bag mr-2" />
          {TablePay}
        </Menu.Item>
      )}
      {userRole === 'support agent' && (
        <Menu.Item>
          <i className="fe fe-shopping-bag mr-2" />
          {TablePay}
        </Menu.Item>
      )}
      {userRole === 'reseller admin' && (
        <Menu.Item>
          <i className="fe fe-shopping-bag mr-2" />
          {userActiveBusiness}
        </Menu.Item>
      )}

      {userRole === 'reseller agent' && (
        <Menu.Item>
          <i className="fe fe-shopping-bag mr-2" />
          {userActiveBusiness}
        </Menu.Item>
      )}
      {userRole === 'partner admin' && (
        <Menu.Item>
          <i className="fe fe-shopping-bag mr-2" />
          {userSoftware}
        </Menu.Item>
      )}
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Badge count={count}>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
