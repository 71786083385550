export const staffRoles = [
  {
    key: 'support admin',
    name: 'Support Admin',
    hidden: true,
  },
  {
    key: 'support agent',
    name: 'Support Agent',
  },
  {
    key: 'reseller admin',
    name: 'Reseller Admin',
  },
  {
    key: 'reseller agent',
    name: 'Reseller Agent',
  },
  {
    key: 'partner admin',
    name: 'Partner Admin',
  },
]

export const BusinessType = [
  {
    key: 'Bar',
    name: 'Bar',
  },
  {
    key: 'Cafe',
    name: 'Cafe',
  },
  {
    key: 'Restaurant',
    name: 'Restaurant',
  },
]
