import { createSelector } from 'reselect'

export const selectWindowsDevice = state => state.storeinfo

export const selectWindowsDeviceData = createSelector([selectWindowsDevice], storeinfo => {
  if (storeinfo.searchwindows && storeinfo.divicelist.devices) {
    const windowssearch = storeinfo.searchwindows.toLowerCase()
    return storeinfo.divicelist.devices.filter(o =>
      o.device_name?.toLowerCase()?.includes(windowssearch),
    )
  }
  return storeinfo.divicelist?.devices
})

export const selectCloverDevice = state => state.storeinfo

export const selectCloverDeviceData = createSelector([selectCloverDevice], storeinfo => {
  if (storeinfo.searchclover && storeinfo.divicelist.devices) {
    const cloversearch = storeinfo.searchclover.toLowerCase()
    return storeinfo.divicelist.devices.filter(o =>
      o.device_name?.toLowerCase()?.includes(cloversearch),
    )
  }
  return storeinfo.divicelist?.devices
})
