import { createSelector } from 'reselect'

export const selectCloseOrder = state => state.order

export const selectActiveStores = state => state.storeinfo

export const selectCloseOrderData = createSelector([selectCloseOrder], order => {
  if (order.searchcloseorder && order.closedorderdata) {
    const closedsearch = order.searchcloseorder.toLowerCase()
    return order.closedorderdata.filter(
      o =>
        o.order_number?.includes(closedsearch) ||
        o.staff?.name?.toLowerCase()?.includes(closedsearch),
    )
  }
  return order.closedorderdata
})
