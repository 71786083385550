export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Manage',
    },
    {
      title: 'Manage Stores',
      key: 'manage-stores',
      icon: 'fe fe-shopping-bag',
      url: '/manage/stores/active',
      extra_urls_one: ['/manage/stores/inactive'],
      extra_urls_two: ['/manage/stores/demo'],
      extra_urls_three: ['/manage/store-info'],
    },
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user',
      url: '/manage/users',
    },
  ]
}
