import apiClient from 'services/axios'

const BASE_URL = 'country'

export default async function load() {
  return apiClient
    .get(`${BASE_URL}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
