import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Form, Switch } from 'antd'
import { injectIntl } from 'react-intl'

const { TextArea } = Input

const mapStateToProps = devicename => ({ devicename })

const { useState } = React

const TablePaySettings = ({ devicename, intl: { formatMessage } }) => {
  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (devicename.storeinfo?.selectedCloverDevice?.settings) {
      form.setFieldsValue({
        print_receipt: devicename.storeinfo?.selectedCloverDevice?.settings?.receipt?.print_receipt,
        print_merchant_copy:
          devicename.storeinfo?.selectedCloverDevice.settings?.receipt?.print_merchant_copy,
        footer_line_text:
          devicename.storeinfo?.selectedCloverDevice?.settings?.receipt?.footer_line_text,
        idle_timeout:
          devicename?.storeinfo?.selectedCloverDevice?.settings?.preferences?.idle_timeout,
        is_tip_option:
          devicename.storeinfo?.selectedCloverDevice?.settings?.preferences?.is_tip_option,
      })
    }
    // eslint-disable-next-line
  }, [form, devicename.storeinfo?.selectedCloverDevice.settings?.receipt.print_receipt])

  const array_val = devicename.storeinfo?.selectedCloverDevice?.settings?.preferences?.tip_options

  const result_ten = array_val.filter(word => word === 10)
  const result_fifteen = array_val.filter(word => word === 15)
  const result_eighteen = array_val.filter(word => word === 18)
  const result_twenty = array_val.filter(word => word === 20)

  return (
    <div>
      <Form form={form} className="mb-4" layout="vertical">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h5 className="mb-4">
                  <strong>{formatMessage({ id: 'text.receiptsettings' })}</strong>
                </h5>

                <div className="row">
                  <div className="col-lg-5 col-sm-12 col-xs-12">
                    <Form.Item
                      name="print_receipt"
                      label={formatMessage({ id: 'form.label.printreceipt' })}
                      valuePropName="checked"
                    >
                      <Switch
                        checkedChildren={formatMessage({ id: 'action.yes' })}
                        unCheckedChildren={formatMessage({ id: 'action.no' })}
                        disabled
                      />
                    </Form.Item>
                  </div>

                  <div className="col-lg-6 col-sm-12 col-xs-12">
                    <Form.Item
                      name="print_merchant_copy"
                      label={formatMessage({ id: 'form.label.printmerchantcopy' })}
                      valuePropName="checked"
                    >
                      <Switch
                        checkedChildren={formatMessage({ id: 'action.yes' })}
                        unCheckedChildren={formatMessage({ id: 'action.no' })}
                        disabled
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <Form.Item
                      name="footer_line_text"
                      label={formatMessage({ id: 'form.label.footerline' })}
                      rules={[
                        {
                          message: formatMessage({ id: 'form.error.footerline.required' }),
                        },
                      ]}
                    >
                      <TextArea
                        placeholder={formatMessage({ id: 'form.placeholder.footerline' })}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        disabled
                      />
                    </Form.Item>
                  </div>
                </div>
                <h5 className="mb-4">
                  <strong>{formatMessage({ id: 'text.preferences' })}</strong>
                </h5>

                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-xs-12">
                    <Form.Item
                      name="is_tip_option"
                      label={formatMessage({ id: 'form.label.tipamount' })}
                      valuePropName="checked"
                    >
                      <Switch
                        size="large"
                        onClick={() => setVisible(!visible)}
                        checkedChildren={formatMessage({ id: 'action.yes' })}
                        unCheckedChildren={formatMessage({ id: 'action.no' })}
                        disabled
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-xs-12">
                    <Form.Item
                      name="idle_timeout"
                      label={formatMessage({ id: 'form.label.idletimeout' })}
                      rules={[
                        {
                          message: formatMessage({ id: 'form.error.idletimeout.required' }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={formatMessage({ id: 'form.placeholder.idletimeout' })}
                        disabled
                      />
                    </Form.Item>
                  </div>

                  {devicename.storeinfo?.selectedCloverDevice?.settings?.preferences
                    ?.is_tip_option === true && (
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                      <Form.Item
                        name="checkedten"
                        label={formatMessage({ id: 'form.label.tipoptions' })}
                      >
                        <Input
                          type="checkbox"
                          name="checkedten"
                          value="10"
                          checked={result_ten.length > 0}
                          disabled
                        />
                        &nbsp; 10% &nbsp;
                        <Input
                          type="checkbox"
                          name="checkedfifteen"
                          value="15"
                          checked={result_fifteen.length > 0}
                          disabled
                        />
                        &nbsp; 15% &nbsp;
                        <Input
                          type="checkbox"
                          name="checkedeighteen"
                          value="15"
                          checked={result_eighteen.length > 0}
                          disabled
                        />
                        &nbsp; 18% &nbsp;
                        <Input
                          type="checkbox"
                          name="checkedtwenty"
                          value="15"
                          checked={result_twenty.length > 0}
                          disabled
                        />
                        &nbsp; 20%
                      </Form.Item>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(TablePaySettings))
