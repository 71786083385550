import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import staff from './staff/sagas'
import settings from './settings/sagas'
import order from './order/sagas'
import invoices from './invoices/sagas'
import storeinfo from './storeinfo/sagas'
import country from './country/sagas'
import softwarelist from './softwarelist/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    staff(),
    settings(),
    order(),
    invoices(),
    storeinfo(),
    country(),
    softwarelist(),
  ])
}
