import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import { login, reset, recover } from 'api/user'
import store from 'store'
import RootAction from 'redux/actions'
import actions from './actions'
import menuactions from '../menu/actions'
import { userStateData } from './reducers'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: true,
    },
  })
  const { response } = yield call(login, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: false,
    },
  })
  if (response) {
    yield call(LOGIN_USER, response)
  }
}

function* LOGIN_USER(response) {
  const { access_token: accessToken, user } = response.data.data
  // const { access_token: accessToken, user, businesses } = response.data.data
  store.set(`user.accessToken`, accessToken)
  store.set(`user.data`, user)
  // const business = businesses[0]
  // const businessLocation = business.locations
  // store.set(`user.business`, business)
  // store.set(`user.businessLocation`, businessLocation)
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken,
      data: user,
      // business,
      // businessLocation,
    },
  })
  yield put({
    type: menuactions.SET_DATA,
  })
  yield history.push('/')
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    const { id, email, name, avatar, role } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        email,
        avatar,
        role,
        authorized: true,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(CLEAR_USER)
}

export function* CLEAR_USER() {
  store.clearAll()
  yield put({
    type: RootAction.CLEAR_STATE,
  })
  yield put({
    type: actions.SET_STATE,
    payload: userStateData,
  })
  yield history.push('/')
}

export function* RECOVER_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: true,
    },
  })
  const { response, error } = yield call(recover, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: false,
    },
  })
  if (response) {
    yield history.push('/')
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        recover: errors,
      },
    })
  }
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: true,
    },
  })
  const { response, error } = yield call(reset, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: false,
    },
  })
  if (response) {
    const { access_token } = response.data
    console.log(access_token)
    yield call(HANDLE_USER_TOKEN, access_token)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        reset: errors,
      },
    })
  }
}

function* HANDLE_USER_TOKEN(access_token) {
  store.set(`user.accessToken`, access_token)
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken: access_token,
      email: null,
      canResendEmail: false,
      resendEmail: false,
    },
  })
  yield history.push('/login')
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeLatest(actions.CLEAR_USER, CLEAR_USER),
    takeLatest(actions.RECOVER_PASSWORD, RECOVER_PASSWORD),
    takeLatest(actions.RESET_PASSWORD, RESET_PASSWORD),
  ])
}
