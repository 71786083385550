import apiClient from 'services/axios'

const BASE_URL = 'supportuser/softwarelist'

export default async function softwarelist() {
  return apiClient
    .get(`${BASE_URL}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
