import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import moment from 'moment-timezone'
import Loading from 'components/app/common/Loading'

const mapStateToProps = ({ dispatch, order }) => ({
  dispatch,
  order,
})

const OrderSummary = ({ order, intl: { formatMessage } }) => {
  const openReceipt = url => {
    window.open(url)
  }

  if (!order.selectedDataone) return <Loading />

  return (
    <div>
      <div className="card">
        <div className="card-body p-2">
          <div className="d-flex flex-wrap m-1">
            <div className="flex-grow-1">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.orderno' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                {order.selectedDataone?.order_number}
              </div>
            </div>
            <div className="flex-grow-1 text-right">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.ordercreatedon' })}
                {moment(order.selectedDataone?.created_at).format(' [On] MMM D, YYYY hh:mm a')}
              </div>
              <div className="font-size-12 text-gray-5">
                <></>
              </div>
            </div>
          </div>

          <div className="d-flex m-2">
            <div className="flex-grow-1">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.ordertableno' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                {order.selectedDataone?.table_number}
              </div>
            </div>

            <div className="flex-grow-1">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.orderseatno' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                {order.selectedDataone?.seat_number}
              </div>
            </div>
            <div className="flex-grow-1 text-right">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.orderusername' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                {order.selectedDataone?.staff?.name}
              </div>
            </div>
          </div>
          <div className="d-flex m-2">
            <div className="flex-grow-1">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.ordertype' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                {order.selectedDataone?.order_type}
              </div>
            </div>
            <div className="flex-grow-1 text-right">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.orderpaymentstatus' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                <span
                  className={`${
                    order.selectedDataone?.payment_status === 'Paid'
                      ? 'custom_theme_color'
                      : 'text-danger'
                  }`}
                >
                  {order.selectedDataone?.payment_status}
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex m-2">
            <div className="flex-grow-1 text-right">
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.orderamountdue' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                <span className="text-danger">${order.selectedDataone?.amount_due.toFixed(2)}</span>
              </div>
            </div>
            <div className="flex-grow-1 text-right" hidden>
              <div className="font-size-12 text-gray-5">
                {formatMessage({ id: 'text.ordertip' })}
              </div>
              <div className="font-size-16 font-weight-bold">
                <span className="custom_theme_color">${order.selectedDataone.tip.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {order.selectedDataone.payments.map(item => (
        <div className="card" key={item.id} name={item.payment_amount}>
          <div className="card-body p-2">
            <div className="d-flex m-2">
              <div className="flex-grow-1 text-left">
                <div className="font-size-12 text-gray-5">
                  {formatMessage({ id: 'text.ordertransactiontype' })}
                </div>
                <div className="font-size-16 font-weight-bold">
                  {order.selectedDataone.transaction_type}
                </div>
              </div>
              <div className="flex-grow-1 text-right">
                <div className="font-size-12 text-gray-5">
                  {formatMessage({ id: 'text.orderpaymentmethod' })}
                </div>
                <div className="font-size-16 font-weight-bold">
                  {item.card_mode} - {item.card_type}
                  {' ('}
                  {item.account_number}
                  {')'}
                </div>
              </div>
            </div>
            <div className="d-flex m-2">
              <div className="flex-grow-1 text-left">
                <div className="font-size-12 text-gray-5">
                  {formatMessage({ id: 'text.orderauthnumber' })}
                </div>
                <div className="font-size-16 font-weight-bold">{item.auth_number}</div>
              </div>

              <div className="flex-grow-1 text-right">
                <div className="font-size-12 text-gray-5">
                  {' '}
                  {formatMessage({ id: 'text.ordertransactiondate' })}
                </div>
                <div className="font-size-16 font-weight-bold">
                  {moment
                    .tz(item.transaction_at, 'America/Los_Angeles')
                    .format(' MMM D, YYYY hh:mm a')}
                </div>
              </div>
            </div>
            <div className="d-flex m-2">
              <div className="flex-grow-1 text-left">
                <div className="font-size-12 text-gray-5">
                  {formatMessage({ id: 'text.ordertip' })}
                </div>
                <div className="font-size-16 font-weight-bold">
                  <span className="custom_theme_color">${item.tip.toFixed(2)}</span>
                </div>
              </div>
              <div className="flex-grow-1 text-right">
                <div className="font-size-12 text-gray-5">
                  {item.tip.toFixed(2) !== '0.00' && (
                    <span>{formatMessage({ id: 'text.orderamountpaidwithtip' })}</span>
                  )}
                  {item.tip.toFixed(2) === '0.00' && (
                    <span>{formatMessage({ id: 'text.orderamountpaid' })}</span>
                  )}
                </div>
                <div className="font-size-16 font-weight-bold">
                  <span className="custom_theme_color">${item.payment_amount.toFixed(2)}</span>
                </div>
              </div>
            </div>

            <div className="d-flex m-2">
              <div className="flex-grow-1 text-right" hidden={item?.payment_type !== 'Card'}>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => openReceipt(item.receipt_url)}
                >
                  <i className="fe fe-file-text mr-2" />
                  {formatMessage({ id: 'action.viewReceipt' })}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(OrderSummary))
