import React from 'react'
import { injectIntl } from 'react-intl'
import { Upload } from 'antd'

const ImagePicker = ({ value, onChange, intl: { formatMessage } }) => {
  const props = {
    multiple: false,
    showUploadList: false,
    beforeUpload: file => {
      const reader = new FileReader()
      reader.onload = () => {
        onChange({
          image_url: value?.image_url || value,
          image_data: reader.result,
          image_file: file,
        })
      }
      reader.readAsDataURL(file)
      return false
    },
  }

  // const temp_logo = 'https://sjfltc.com/wp-content/uploads/2016/10/logo-placeholder.jpg'

  return (
    <>
      <div className="mb-2">
        <img
          className="img-fluid"
          src={value?.image_data || value?.image_url || value}
          // src={value?.image_data || value?.image_url || value || temp_logo}
          alt={formatMessage({ id: 'desc.logo' })}
          style={{ maxHeight: 200 }}
        />
      </div>
      <Upload {...props}>
        <button type="button" className="btn btn-light mb-2">
          <i className="fe fe-edit mr-1" aria-hidden="true" />
          {formatMessage({ id: 'action.change' })}
        </button>
      </Upload>
    </>
  )
}

export default injectIntl(ImagePicker)
