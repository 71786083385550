const actions = {
  SET_STATE: 'storeinfo/SET_STATE',
  STOP_SERVICE: 'storeinfo/STOP_SERVICE',
  UPDATE_STATUS: 'storeinfo/UPDATE_STATUS',
  SHOW_CREATE: 'storeinfo/SHOW_CREATE',
  ADDNOTES: 'storeinfo/ADDNOTES',
  UPDATE_STORE_INFO: 'storeinfo/UPDATE_STORE_INFO',
  SHOW_TRANSACTIONS: 'storeinfo/SHOW_TRANSACTIONS',
  SHOW_CLOSE_ORDER_TRANSACTIONS: 'storeinfo/SHOW_CLOSE_ORDER_TRANSACTIONS',
  HIDE_OPEN_ORDER_DRAWER: 'storeinfo/HIDE_OPEN_ORDER_DRAWER',
  LOAD_REPORT: 'storeinfo/LOAD_REPORT',
  HIDE_CLOSE_ORDER_DRAWER: 'storeinfo/HIDE_CLOSE_ORDER_DRAWER',
  VIEW_INVOICE: 'storeinfo/VIEW_INVOICE',
  VIEW_ACTIVITY: 'storeinfo/VIEW_ACTIVITY',
  SHOW_RESEND: 'storeinfo/SHOW_RESEND',
  HIDE_RESEND: 'storeinfo/HIDE_RESEND',
  SHOW_BILLING_TYPE: 'storeinfo/SHOW_BILLING_TYPE',
  SHOW_SETTINGS: 'storeinfo/SHOW_SETTINGS',
  LOAD_ALL: 'storeinfo/LOAD_ALL',
  LOAD_ALL_ACTIVE: 'storeinfo/LOAD_ALL_ACTIVE',
  LOAD_INFO: 'storeinfo/LOAD_INFO',
  LOAD_DEVICES: 'storeinfo/LOAD_DEVICES',
  LOAD_NOTES: 'storeinfo/LOAD_NOTES',
  SAVE_NOTES: 'storeinfo/SAVE_NOTES',
  UPDATE_STORE: 'storeinfo/UPDATE_STORE',
  LOAD_LOGS: 'storeinfo/LOAD_LOGS',
  RESENDEMAIL: 'storeinfo/RESENDEMAIL',
}

export default actions
