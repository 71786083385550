/* eslint-disable camelcase */
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadopenorder, loadclosedorder, loadclosedorderreport } from 'api/order'
import actions from './actions'

// const path = window.location.pathname
// const userId = path.replace('/manage/store-info/', '')

export function* LOAD_ALL(payload) {
  const userId = payload.payload
  const { response } = yield call(loadopenorder, userId)
  if (response) {
    const { orders } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        openorderdata: orders,
      },
    })
  }
}

export function* LOAD_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const userId = payload.idval
  const { response } = yield call(loadclosedorder, userId, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { orders } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        closedorderdata: orders,
      },
    })
  }
}

export function* LOAD_CLOSED_ORDER_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const userId = payload.idval
  const { response } = yield call(loadclosedorderreport, userId, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { orders } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        closedorderdata: orders,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.LOAD_REPORT, LOAD_REPORT),
    takeLatest(actions.LOAD_CLOSED_ORDER_REPORT, LOAD_CLOSED_ORDER_REPORT),
  ])
}
