/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  storeid: '',
  // alt_phone_number: null,
  data: null,
  image: null,
  data1: null,
  data2: null,
  data3: null,
  data4: null,
  data5: null,
  data6: null,
  data7: null,
  data8: null,
  data9: null,
  data10: null,
  data11: null,
  data12: null,
  // data13: null,
  data14: null,
  data15: null,
  data16: null,
  data17: null,
  data18: null,
  data19: null,
  data20: null,
  data21: null,
  reseller: null,
  logsdata: null,
  storelist: null,
  storeinfo: null,
  divicelist: null,
  search: null,
  searchactive: null,
  searchinactive: null,
  searchdemo: null,
  searchwindows: null,
  notesinfo: null,
  searchclover: null,
  displayLogDrawer: false,
  openorderdata: null,
  closedorderdata: null,
  vitaldata: null,
  selectedData: null,
  selectedDataone: '',
  selectedDatatwo: '',
  selectdevicesettings: '',
  selectedCloverDevice: '',
  selectedWindowsDevice: '',
  displayTransactionHistoryDrawer: false,
  displayCloseOrderSummaryDrawer: false,
  displaysettingsDrawer: false,
  displayPaymentDrawer: false,
  displayActivityDrawer: false,
  displayRemoveModal: false,
  creating: false,
  removing: false,
  loading: false,
  updating: false,
  uploading: false,
}

export default function storeinfoReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, selectedWindowsDevice: action.payload.devices, displayLogDrawer: true }

    case actions.SHOW_TRANSACTIONS:
      return {
        ...state,
        selectedDataone: action.payload.storeinfo,
        displayTransactionHistoryDrawer: true,
      }

    case actions.HIDE_OPEN_ORDER_DRAWER:
      return { ...state, displayTransactionHistoryDrawer: false }

    case actions.SHOW_CLOSE_ORDER_TRANSACTIONS:
      return {
        ...state,
        selectedDatatwo: action.payload.storeinfo,
        displayCloseOrderSummaryDrawer: true,
      }

    case actions.HIDE_CLOSE_ORDER_DRAWER:
      return { ...state, displayCloseOrderSummaryDrawer: false }

    case actions.VIEW_INVOICE:
      return { ...state, displayLogDrawer: true }

    case actions.VIEW_ACTIVITY:
      return { ...state, displayActivityDrawer: true }

    case actions.SHOW_RESEND:
      return { ...state, selectedData: action.payload.storeinfo, displayRemoveModal: true }
    case actions.HIDE_RESEND:
      return { ...state, displayRemoveModal: false }
    case actions.ADDNOTES:
      return {
        ...state,
        notesinfo: [...state.notesinfo, action.payload.notesinfo],
        updating: false,
      }
    case actions.UPDATE_STORE_INFO:
      return {
        ...state,
        ...action.payload,
        updating: false,
      }
    case actions.SHOW_BILLING_TYPE:
      return { ...state, displayPaymentDrawer: true }
    case actions.SHOW_SETTINGS:
      return {
        ...state,
        selectedCloverDevice: action.payload.devicename,
        displaysettingsDrawer: true,
      }

    default:
      return state
  }
}
