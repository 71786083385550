import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import StoreInfoAction from 'redux/storeinfo/actions'
import moment from 'moment'
import { createStructuredSelector } from 'reselect'
import { selectActiveStores, selectNotesInfo } from 'redux/storeinfo/notesselectors'
import { selectUsers } from 'redux/storeinfo/storelistselectors'
import { selectSettings } from 'redux/settings/selectors'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Tooltip, Form, Input, Button } from 'antd'
import Loading from 'components/app/common/Loading'
import style from './style.module.scss'

const mapStateToProps = () =>
  createStructuredSelector({
    usersdata: selectUsers,
    storesone: selectActiveStores,
    notesdata: selectNotesInfo,
    settings: selectSettings,
  })

const scrollToRef = ref => window.scrollTo(500, ref.current.offsetTop)

const Notes = ({ dispatch, usersdata, storesone, notesdata, intl: { formatMessage } }) => {
  const path = window.location.pathname

  const user_permission = usersdata?.data?.supportuser?.permission

  const userId = path.replace('/manage/store-info/', '')

  const [form] = Form.useForm()

  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  useEffect(() => {
    dispatch({
      type: StoreInfoAction.LOAD_NOTES,
      payload: userId,
    })
  }, [dispatch, userId])

  const inputRef = useRef(null)

  const onFinish = values => {
    dispatch({
      type: StoreInfoAction.SAVE_NOTES,
      payload: {
        business_id: userId,
        ...values,
      },
    })
    form.resetFields()

    inputRef.current.focus()
  }

  if (!notesdata) return <Loading />

  const groups = {}

  // eslint-disable-next-line
  notesdata.forEach(function(notesinfo) {
    const date = notesinfo.created_at.split('T')[0]
    if (!groups[date]) {
      groups[date] = []
    }
    groups[date].push(notesinfo)
    return groups
  }, {})

  const groupArrays = Object.keys(groups).map(created_at => {
    return {
      created_at,
      listed_data: groups[created_at],
    }
  })

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="notes-section height-400 d-flex flex-column justify-content-center">
            <PerfectScrollbar
              onScrollY={container => console.log(`scrolled to: ${container.scrollTop}.`)}
            >
              <div className={style.contentWrapper} ref={myRef}>
                {groupArrays?.map(item => (
                  <div key={item.created_at}>
                    <div className="text-gray-4 font-size-13 text-center text-capitalize">
                      {moment(item?.created_at).format('LL')}
                    </div>
                    {item.listed_data.map(item1 => (
                      <div
                        className={`${style.message} ${style.answer} ${style.answermarginbottom}`}
                      >
                        <div className={style.messageContent}>
                          <div className="text-gray-4 text-capitalize" style={{ fontSize: '11px' }}>
                            {item1?.user?.first_name}
                            <Tooltip
                              title={moment(item1?.created_at).format('MMM D [@] hh:mm A')}
                              placement="topLeft"
                            >
                              &nbsp; <span title="">{moment(item1?.created_at).format('LT')}</span>
                            </Tooltip>
                          </div>
                          <div>{item1?.message}</div>
                        </div>

                        <div className={`${style.messageAvatar} kit__utils__avatar`}>
                          <img src="resources/images/avatars/avatar-2.png" alt="You" />
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </PerfectScrollbar>
          </div>
          {user_permission?.write_notes === true && (
            <Form
              className="mb-4"
              layout="vertical"
              onFinish={onFinish}
              form={form}
              hideRequiredMark
            >
              <Form.Item name="message">
                <div className="input-group mb-3" style={{ flexWrap: 'nowrap' }}>
                  <Input
                    type="text"
                    placeholder={formatMessage({ id: 'form.placeholder.message' })}
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    ref={inputRef}
                  />
                  <div className="input-group-append">
                    <Button
                      className="btn btn-primary"
                      type="primary"
                      htmlType="submit"
                      loading={storesone.updating}
                      onClick={executeScroll}
                    >
                      {formatMessage({ id: 'text.notes.button_message' })}
                    </Button>
                  </div>
                </div>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(Notes))
