import PhoneNumber from 'awesome-phonenumber'

export const parseType = (type, types) => {
  return types[type]
}

export const phoneNumberSignificant = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('significant')
  }
  return null
}

export const phoneNumberNational = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('national')
  }
  return null
}

export const phoneNumberE164 = data => {
  if (data) {
    const phoneNumber = new PhoneNumber(data, 'CA') // todo: auto detect
    return phoneNumber.getNumber('e164')
  }
  return null
}
