/* eslint-disable no-underscore-dangle,camelcase */
import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { BusinessType } from 'utils/data'
import { phoneNumberNational } from 'utils/parser'
import StoreInfoAction from 'redux/storeinfo/actions'
import countryAction from 'redux/country/actions'
import softwarelistAction from 'redux/softwarelist/actions'
import { createStructuredSelector } from 'reselect'
import {
  selectActiveStores,
  selectActiveStoreListData,
  selectCountry,
  selectUsers,
  selectSOftwareList,
} from 'redux/storeinfo/storelistselectors'
import { selectSettings } from 'redux/settings/selectors'
import { Form, Input, Typography, Alert, Select, Button, Radio, Modal } from 'antd'
import MaskedInput from 'antd-mask-input'
import LogoPicker from 'components/app/common/LogoPicker'

const { Title } = Typography

const { confirm } = Modal

const { Option, OptGroup } = Select

const StatusOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
  { label: 'Demo', value: 'Demo' },
]

const mapStateToProps = () =>
  createStructuredSelector({
    usersdata: selectUsers,
    country: selectCountry,
    stores: selectActiveStores,
    storeListData: selectActiveStoreListData,
    settings: selectSettings,
    softlist: selectSOftwareList,
  })

const StoreDetail = ({
  dispatch,
  country,
  stores,
  usersdata,
  softlist,
  intl: { formatMessage },
}) => {
  const user_permission = usersdata?.data?.supportuser?.permission
  const [form] = Form.useForm()

  const path = window.location.pathname

  const userId = path.replace('/manage/store-info/', '')

  const confirmemail = value => {
    confirm({
      title: 'Are you sure you want to resend activation key?',
      onOk: () => {
        resendWelcomeEmail(value)
      },
      okText: 'Ok',
      cancelText: 'Cancel',
    })
  }

  const resendWelcomeEmail = values => {
    dispatch({
      type: StoreInfoAction.RESENDEMAIL,
      payload: values,
    })
  }

  useEffect(() => {
    dispatch({
      type: softwarelistAction.LOAD,
    })
  }, [dispatch])

  const restaurant_software_val = softlist?.softdata

  useEffect(() => {
    dispatch({
      type: countryAction.LOAD,
    })
  }, [dispatch])

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: StoreInfoAction.LOAD_INFO,
        payload: userId,
      }) // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    if (stores) {
      form.setFieldsValue({
        name: stores?.data1,
        type: stores?.data2,
        address: stores?.data3,
        city: stores?.data4,
        province: stores?.data5,
        postal_code: stores?.data6,
        country: stores?.data7,
        time_zone: stores?.data8,
        first_name: stores?.data9,
        last_name: stores?.data10,
        email: stores?.data11,
        phone_number: phoneNumberNational(stores?.data12),
        alt_phone_number: phoneNumberNational(stores?.data14),
        logo: stores?.data15,
        account_type: stores?.data16,
        partner_id: stores?.data21,
      })
    }
    // eslint-disable-next-line
  }, [form, stores])

  const onFinish = values => {
    delete values.logo
    delete values.time_zone

    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        data16: values.account_type,
      },
    })

    dispatch({
      type: StoreInfoAction.UPDATE_STORE,
      payload: values,
    })
  }

  useEffect(() => {
    const countryValload = stores?.data7
    const selectedCountry = country?.data?.find(o => o.country_name === countryValload)
    console.log(selectedCountry)
    // eslint-disable-next-line
  }, [dispatch])

  const onValuesChange = changedValues => {
    const { province: provinceVal } = changedValues
    if (provinceVal) {
      const countryvalue = country.data
      const countrydata = countryvalue.find(cc =>
        cc.province_list.some(province => province.province_name === provinceVal),
      )
      form.setFieldsValue({
        country: countrydata?.country_name,
      })
    }
  }

  const toInputLowercase = e => {
    e.target.value = e.target.value.toLowerCase()
  }

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        hideRequiredMark
      >
        <div className="row">
          <Title className="col-sm-12 col-md-8" level={4}>
            <></>
          </Title>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="d-flex mb-2">
                  <h4 className="flex-grow-1">
                    <strong>{formatMessage({ id: 'text.storedetail.storedetailinfo' })}</strong>
                  </h4>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Item
                      name="name"
                      label={formatMessage({ id: 'form.storedetail.label.storename' })}
                    >
                      <Input
                        size="large"
                        placeholder={formatMessage({
                          id: 'form.storedetail.placeholder.storename',
                        })}
                        maxLength={75}
                        disabled={user_permission?.write_store_info === false}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-12">
                    <Form.Item
                      name="type"
                      label={formatMessage({ id: 'form.storedetail.label.storetype' })}
                    >
                      <Select
                        placeholder={formatMessage({ id: 'form.label.businesstype' })}
                        size="large"
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={user_permission?.write_store_info === false}
                      >
                        {BusinessType.filter(o => !o.hidden).map(o => {
                          return (
                            <Option key={o.key} value={o.name}>
                              {o.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-12">
                    <Form.Item
                      name="address"
                      label={formatMessage({ id: 'form.storedetail.label.storeaddress' })}
                    >
                      <Input
                        size="large"
                        placeholder={formatMessage({
                          id: 'form.storedetail.placeholder.storeaddress',
                        })}
                        maxLength={64}
                        disabled={user_permission?.write_store_info === false}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="city"
                      label={formatMessage({ id: 'form.storedetail.label.storecity' })}
                    >
                      <Input
                        size="large"
                        placeholder={formatMessage({
                          id: 'form.storedetail.placeholder.storecity',
                        })}
                        maxLength={20}
                        disabled={user_permission?.write_store_info === false}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item
                      name="province"
                      label={formatMessage({ id: 'form.storedetail.label.storeprovince' })}
                    >
                      <Select
                        selected
                        placeholder={formatMessage({ id: 'form.placeholder.province' })}
                        size="large"
                        showSearch
                        disabled={user_permission?.write_store_info === false}
                      >
                        {country.data?.map(co => (
                          <OptGroup key={co.id} value={co.id} label={co.country_name}>
                            {co.province_list?.map(co2 => (
                              <Option key={co2.province_id} value={co2.province_name}>
                                {co2.province_name}
                              </Option>
                            ))}
                          </OptGroup>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="country"
                      label={formatMessage({ id: 'form.storedetail.label.storecountry' })}
                    >
                      <Select
                        placeholder={formatMessage({ id: 'form.placeholder.country' })}
                        size="large"
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        notFoundContent={formatMessage({ id: 'form.error.empty.select' })}
                        disabled={user_permission?.write_store_info === false}
                      >
                        {country.data?.map(o => (
                          <Option key={o.country_name} value={o.country_name}>
                            {o.country_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item
                      name="postal_code"
                      label={formatMessage({ id: 'form.storedetail.label.storepostalcode' })}
                    >
                      <Input
                        size="large"
                        placeholder={formatMessage({
                          id: 'form.storedetail.placeholder.storepostalcode',
                        })}
                        maxLength={8}
                        disabled={user_permission?.write_store_info === false}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item
                      name="time_zone"
                      label={formatMessage({ id: 'form.label.timezone' })}
                    >
                      <Input
                        size="large"
                        placeholder={formatMessage({
                          id: 'form.placeholder.timezone',
                        })}
                        maxLength={20}
                        disabled
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="partner_id"
                      label={formatMessage({ id: 'form.label.pos_software' })}
                    >
                      <Select
                        placeholder={formatMessage({
                          id: 'form.label.select.restaurant_software',
                        })}
                        size="large"
                        showSearch
                        disabled={user_permission?.write_store_info === false}
                      >
                        {restaurant_software_val?.map(o => (
                          <Option key={o.id} value={o.id}>
                            {o.restaurant_software}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="card" style={{ display: 'none' }}>
              <div className="card-body">
                <h4 className="mb-2">
                  <strong>{formatMessage({ id: 'text.logo' })}</strong>
                </h4>
                <div className="mb-4">
                  <Alert message={formatMessage({ id: 'note.logoSize' })} type="info" showIcon />
                </div>
                <div className="text-center">
                  <Form.Item name="logo">
                    <LogoPicker disabled={user_permission?.write_store_info === false} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="mb-4">
                  <strong>{formatMessage({ id: 'text.storedetail.contactdetails' })}</strong>
                </h4>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      name="first_name"
                      label={formatMessage({ id: 'form.storedetail.label.firstName' })}
                    >
                      <Input
                        size="large"
                        placeholder={formatMessage({
                          id: 'form.storedetail.placeholder.firstName',
                        })}
                        maxLength={20}
                        disabled={user_permission?.write_store_info === false}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item
                      name="last_name"
                      label={formatMessage({ id: 'form.storedetail.label.lastName' })}
                    >
                      <Input
                        size="large"
                        placeholder={formatMessage({
                          id: 'form.storedetail.placeholder.lastName',
                        })}
                        maxLength={20}
                        disabled={user_permission?.write_store_info === false}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-md-6">
                    <Form.Item
                      name="phone_number"
                      label={formatMessage({ id: 'form.storedetail.label.phone_number' })}
                      rules={[
                        () => ({
                          validator(rule, value) {
                            const phoneNumber = phoneNumberNational(value)
                            if (!value || (phoneNumber && phoneNumber.length === 14)) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error(formatMessage({ id: 'form.error.phone.invalid' })),
                            )
                          },
                        }),
                      ]}
                    >
                      <MaskedInput
                        mask="(111) 111-1111"
                        placeholder={formatMessage({
                          id: 'form.storedetail.placeholder.phone_number',
                        })}
                        size="large"
                        disabled={user_permission?.write_store_info === false}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      name="alt_phone_number"
                      label={formatMessage({ id: 'form.storedetail.label.alternate_phone_number' })}
                      rules={
                        [
                          // () => ({
                          //   validator(rule, value) {
                          //     if (!value || value.replaceAll('_', '').length === 10) {
                          //       return Promise.resolve()
                          //     }
                          //     return Promise.reject(
                          //       new Error(formatMessage({ id: 'form.error.phone.invalid' })),
                          //     )
                          //   },
                          // }),
                        ]
                      }
                    >
                      <MaskedInput
                        mask="(111) 111-1111"
                        placeholder={formatMessage({
                          id: 'form.storedetail.placeholder.alternate_phone_number',
                        })}
                        size="large"
                        disabled={user_permission?.write_store_info === false}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <div className="ant-row ant-form-item">
                      <div
                        className="ant-col ant-form-item-label w-100"
                        style={{ position: 'relative' }}
                      >
                        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                        <label
                          htmlFor="email"
                          className="ant-form-item-required ant-form-item-required-mark-optional"
                          title={formatMessage({ id: 'form.label.email' })}
                        >
                          {formatMessage({ id: 'form.label.email' })}
                        </label>
                        <a
                          href="javascript:void(0)"
                          onClick={() => confirmemail(userId)}
                          className="kit__utils__link font-size-14 pull-right"
                          style={{ color: '#b51241' }}
                        >
                          {formatMessage({ id: 'action.resendWelcomeEmail' })}
                        </a>
                      </div>
                      <Form.Item name="email">
                        <Input
                          placeholder={formatMessage({ id: 'form.placeholder.email' })}
                          size="large"
                          maxLength={32}
                          onInput={toInputLowercase}
                          disabled={user_permission?.write_store_info === false}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="row">
                  {user_permission?.write_store_info === true && (
                    <div className="col-md-12">
                      <Form.Item
                        name="account_type"
                        label={formatMessage({ id: 'form.storedetail.label.status' })}
                      >
                        <Radio.Group
                          options={StatusOptions}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                  )}
                  {user_permission?.write_store_info === false && (
                    <div className="col-md-4">
                      <Form.Item
                        name="account_type"
                        label={formatMessage({ id: 'form.storedetail.label.status' })}
                      >
                        <Input size="large" disabled />
                      </Form.Item>
                    </div>
                  )}
                </div>
                {user_permission?.write_subscription === true && (
                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <div className="mb-4 text-right">
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          loading={stores.updating}
                        >
                          {formatMessage({ id: 'action.update' })}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreDetail))
