import React, { useState, useEffect, Suspense } from 'react'
import { connect, useDispatch } from 'react-redux'
import { injectIntl } from 'react-intl'
import OrderAction from 'redux/order/actions'
import { createStructuredSelector } from 'reselect'
import {
  selectOrder,
  selectOrderData,
  selectActiveStores,
} from 'redux/storeinfo/openorderselectors'
// import Empty from 'components/app/common/Loading'
// import { selectActiveStores } from 'redux/storeinfo/storelistselectors'
import { selectSettings } from 'redux/settings/selectors'
import { Input, Table, Form, Drawer, Spin } from 'antd'
import OrderSummary from './TransactionHistory'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    stores: selectActiveStores,
    orderone: selectOrder,
    orderDataone: selectOrderData,
    settings: selectSettings,
  })

const OpenOrders = ({ orderone, orderDataone, settings, intl: { formatMessage } }) => {
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)

  const path = window.location.pathname

  const idval = path.replace('/manage/store-info/', '')

  useEffect(() => {
    dispatch({
      type: OrderAction.LOAD_ALL,
      payload: idval,
    })
  }, [dispatch, idval])

  const showTransactionHistory = orderRecord => {
    dispatch({
      type: OrderAction.SHOW_TRANSACTIONS,
      payload: {
        order: orderRecord,
      },
    })
  }

  const hideTransactionHistory = () => {
    dispatch({
      type: OrderAction.HIDE_OPEN_ORDER_DRAWER,
    })
  }

  const onSearch = value => {
    dispatch({
      type: OrderAction.SET_STATE,
      payload: { searchopenorder: value },
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.openorder' }),
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.openorder.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}`,
    },
    {
      title: formatMessage({ id: 'text.openorder.orderno' }),
      dataIndex: 'order_number',
      key: 'order_number',
    },

    {
      title: formatMessage({ id: 'text.openorder.ordertype' }),
      dataIndex: 'order_type',
      key: 'order_type',
    },

    {
      title: formatMessage({ id: 'text.openorder.tableno' }),
      dataIndex: 'table_number',
      key: 'table_number',
    },
    {
      title: formatMessage({ id: 'text.openorder.seatno' }),
      dataIndex: 'seat_number',
      key: 'seat_number',
    },
    {
      title: formatMessage({ id: 'text.openorder.ordertotal' }),
      dataIndex: 'order_total',
      key: 'order_total',
      render: text => <>${text.toFixed(2)}</>,
    },
    {
      title: formatMessage({ id: 'text.openorder.paymentstatus' }),
      key: 'payment_status',
      render: (text, record) => (
        <>
          {record.payment_status}
          &nbsp;&nbsp;
          <i
            className="fa fa-list-alt"
            aria-hidden="true"
            onClick={() => showTransactionHistory(record)}
          >
            <></>
          </i>
        </>
      ),
    },
    {
      title: formatMessage({ id: 'text.openorder.user' }),
      dataIndex: ['staff', 'name'],
      key: 'name',
    },
  ]

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              onSearch={onSearch}
              style={{ width: '100%', height: '100%' }}
              placeholder={formatMessage({ id: 'text.openorder.search' })}
              size="large"
              allowClear
            />
          </Form.Item>
        </div>
      </div>

      <Suspense fallback={<Spin />}>
        {orderDataone?.length >= 0 ? (
          <Table
            dataSource={orderDataone}
            columns={columns}
            locale={locale}
            rowKey="_id"
            pagination={{
              onChange(current) {
                setPage(current)
              },
            }}
          />
        ) : (
          <Table
            columns={columns}
            locale={locale}
            loading={{
              indicator: (
                <div>
                  <Spin />
                </div>
              ),
            }}
          />
        )}
      </Suspense>

      <Drawer
        title={formatMessage({ id: 'text.orderinfo' })}
        placement="right"
        destroyOnClose
        visible={orderone.displayTransactionHistoryDrawer}
        onClose={hideTransactionHistory}
        width={settings.isMobileView ? '100%' : '44%'}
      >
        <OrderSummary />
      </Drawer>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(OpenOrders))
