/* eslint-disable */
import React, { useState, Suspense } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import StoreInfoAction from 'redux/storeinfo/actions'
import { createStructuredSelector } from 'reselect'
import {
  selectWindowsDevice,
  selectCloverDevice,
  selectWindowsDeviceData,
  selectCloverDeviceData,
} from 'redux/storeinfo/deviceselectors'
import { selectSettings } from 'redux/settings/selectors'
import { Input, Table, Form, Typography, Drawer, Spin } from 'antd'
import TablePaySettings from '../../TablePaySettings'

const { Search } = Input

const { Text } = Typography

const mapStateToProps = () =>
  createStructuredSelector({
    deviceone: selectWindowsDevice,
    devicetwo: selectCloverDevice,
    deviceDataone: selectWindowsDeviceData,
    deviceDatatwo: selectCloverDeviceData,
    settings: selectSettings,
  })

const PaymentTerminal = ({
  dispatch,
  settings,
  deviceDatatwo,
  deviceone,
  intl: { formatMessage },
}) => {
  const [page, setPage] = useState(1)

  const selectedCloverValue = 'Clover'
  const filterCloverArray = deviceDatatwo?.filter(data => data.device_type === selectedCloverValue)

  const showSettingsDrawer = deviceRecord => {
    dispatch({
      type: StoreInfoAction.SHOW_SETTINGS,
      payload: {
        devicename: deviceRecord,
      },
    })
  }

  const hideSettingsDrawer = () => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: {
        displaysettingsDrawer: false,
      },
    })
  }

  const onSearch = value => {
    dispatch({
      type: StoreInfoAction.SET_STATE,
      payload: { searchclover: value },
    })
  }

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.paymentterminal' }),
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.deviceinfo.sno' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * 10 + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.deviceinfo.devicename' }),
      dataIndex: 'device_name',
      key: 'device_name',
    },
    {
      title: formatMessage({ id: 'text.deviceinfo.version' }),
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: formatMessage({ id: 'text.deviceinfo.ipaddress' }),
      dataIndex: 'ip_address',
      key: 'ip_address',
    },
    {
      title: formatMessage({ id: 'text.deviceinfo.macaddress' }),
      dataIndex: 'mac_address',
      key: 'mac_address',
    },
    {
      title: formatMessage({ id: 'text.deviceinfo.Settings' }),
      dataIndex: 'settings',
      key: 'settings',
      render: (text, record) => (
        <>
          <i
            className="fe fe-settings mr-1"
            aria-hidden="true"
            onClick={() => showSettingsDrawer(record)}
          />
        </>
      ),
    },
  ]

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <Form.Item>
            <Search
              style={{ width: '100%' }}
              placeholder={formatMessage({ id: 'form.paymentterminal.placeholder.search' })}
              size="large"
              onSearch={onSearch}
              allowClear
            />
          </Form.Item>
        </div>
      </div>
      <Suspense fallback={<Spin />}>
        {filterCloverArray?.length >= 0 ? (
          <Table
            dataSource={filterCloverArray}
            columns={columns}
            locale={locale}
            rowKey="_id"
            pagination={{
              onChange(current) {
                setPage(current)
              },
            }}
          />
        ) : (
          <Table
            columns={columns}
            locale={locale}
            loading={{
              indicator: (
                <div>
                  <Spin />
                </div>
              ),
            }}
          />
        )}
      </Suspense>
      <Drawer
        title={formatMessage({ id: 'text.tablepaysettings' })}
        placement="right"
        destroyOnClose
        visible={deviceone.displaysettingsDrawer}
        onClose={hideSettingsDrawer}
        width={settings.isMobileView ? '100%' : '44%'}
      >
        <TablePaySettings />
      </Drawer>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(PaymentTerminal))
