import apiClient from 'services/axios'

const BASE_URL1 = 'business'
const BASE_URL2 = 'logs'
const BASE_URL3 = 'note'
const BASE_URL4 = 'device/update'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL1}/all`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resendwelcomeemail(userId) {
  return apiClient
    .get(`${BASE_URL1}/${userId}/resend`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadinfo(userId) {
  return apiClient
    .get(`${BASE_URL1}/${userId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function savenotes(payload) {
  return apiClient
    .post(`${BASE_URL3}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function statusUpdate(payload) {
  return apiClient
    .post(`${BASE_URL4}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadLogo(BusinessId, payload) {
  return apiClient
    .post(`${BASE_URL1}/${BusinessId}/upload-logo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateStore(BusinessId, payload) {
  return apiClient
    .put(`${BASE_URL1}/${BusinessId}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadLogs(businessId, deviceId) {
  return apiClient
    .get(`${BASE_URL2}/${businessId}/${deviceId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
