import { all, call, put, takeLatest } from 'redux-saga/effects'
import softwarelist from 'api/softwarelist'
import actions from './actions'

export function* LOAD() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      softdata: null,
      loading: true,
    },
  })
  const { response } = yield call(softwarelist)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { restaurant_software } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        softdata: restaurant_software,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD)])
}
